import { CREDIT_NOTES_REDUX_CONSTANTS } from '../../reduxConstants/invoiceReduxConstants/creditNotesReduxConstants';
import { LOGIN_REDUX_CONSTANTS } from '../../reduxConstants/loginReduxConstants';

interface invoiceProps {
  creditNotesList: Record<string, any>;
  creditNoteDetail: Record<string, any>;
  viewCreditNoteDetails: Record<string, any>;
  uploadedCreditNoteDetails: Record<string, any>;
  invoiceDropdownList: Record<string, any>[];
  supplierList: Record<string, any> | null;
}

const initialCreditNotesReducer: invoiceProps = {
  creditNotesList: {},
  creditNoteDetail: {
    products: null,
    reasonForCredit: '',
    pictureURLs: [],
  },
  viewCreditNoteDetails: {},
  uploadedCreditNoteDetails: {
    creditId: '',
    creditTotal: '',
    invoiceId: '',
    invoiceNumber: '',
    fileURL: '',
    invoiceStatus: '',
    supplierId: {
      supplierEmail: '',
      supplierName: '',
    },
    orderDate: '',
    netTotal: 0,
    taxAmount: 0,
    requestedBy: '',
    products: null,
  },
  invoiceDropdownList: [],
  supplierList: null,
};

export const creditNotesReducer = (
  state = initialCreditNotesReducer,
  action: { type: string; data: Record<string, any>; status: boolean }
) => {
  switch (action.type) {
    case CREDIT_NOTES_REDUX_CONSTANTS.UPLOAD_PHOTO_FOR_CREDIT:
      return {
        ...state,
        creditNoteDetail: {
          ...state.creditNoteDetail,
          pictureURLs: action.data,
        },
      };
    case CREDIT_NOTES_REDUX_CONSTANTS.GET_SUPPLIER_LIST:
      return { ...state, supplierList: action.data };

    case CREDIT_NOTES_REDUX_CONSTANTS.ON_CHANGE_CREDIT_NOTE_DATA:
      const { name, value } = action.data;
      return {
        ...state,
        creditNoteDetail: {
          ...state.creditNoteDetail,
          [name]: value,
        },
      };

    case CREDIT_NOTES_REDUX_CONSTANTS.GENERATE_CREDIT:
      return { ...state, creditNoteDetail: action.data };

    case CREDIT_NOTES_REDUX_CONSTANTS.RESET_CREDIT_NOTE_DATA:
      return {
        ...state,
        creditNoteDetail: {
          ...initialCreditNotesReducer.creditNoteDetail,
          products: null,
        },
        viewCreditNoteDetails: {
          ...initialCreditNotesReducer.viewCreditNoteDetails,
        },
        uploadedCreditNoteDetails: {
          ...initialCreditNotesReducer.uploadedCreditNoteDetails,
          products: null,
        },
      };

    case CREDIT_NOTES_REDUX_CONSTANTS.CREDIT_NOTES_LIST:
      return { ...state, creditNotesList: action.data };

    case CREDIT_NOTES_REDUX_CONSTANTS.GET_CREDIT_NOTE_DETAILS:
      return { ...state, viewCreditNoteDetails: action.data };
    case CREDIT_NOTES_REDUX_CONSTANTS.INVOICE_DROPDOWN_LIST:
      return { ...state, invoiceDropdownList: action.data };

    case CREDIT_NOTES_REDUX_CONSTANTS.GET_UPLOADED_CREDIT_NOTE_DETAILS:
      return {
        ...state,
        uploadedCreditNoteDetails: action.data,
        creditNoteDetail: {
          ...state.creditNoteDetail,
          ...action.data,
        },
      };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialCreditNotesReducer,
      };

    default:
      return state;
  }
};
