import { INVOICE_URLS } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

export const creditNotesApiServices = {
  uploadPhotoForCredit: (
    data: FormData,
    config: Record<string, Record<string, string>>
  ) =>
    ApiService.postData(
      INVOICE_URLS.CREDIT_NOTES_URLS.UPLOAD_PHOTO_FOR_CREDIT,
      data,
      config
    ),
  getSupplierList: () =>
    ApiService.getData(
      `${INVOICE_URLS.CREDIT_NOTES_URLS.GET_SUPPLIER_LIST_IN_CREDITNOTE}`
    ),
  generateCredit: (data: Record<string, any>, invoiceId: string) =>
    ApiService.postData(
      `${INVOICE_URLS.CREDIT_NOTES_URLS.GENERATE_CREDIT}${invoiceId}`,
      data
    ),
  saveCreditNoteDetails: (data: Record<string, any>, creditId: string) =>
    ApiService.putData(
      `${INVOICE_URLS.CREDIT_NOTES_URLS.SAVE_CREDIT_NOTE}${creditId}`,
      data
    ),
  CreditNotesList: (params: Record<string, string | number>) =>
    ApiService.getData(INVOICE_URLS.CREDIT_NOTES_URLS.CREDIT_NOTE_LIST, {
      params,
    }),
  InvoiceDropDownList: () =>
    ApiService.getData(INVOICE_URLS.CREDIT_NOTES_URLS.INVOICE_DROPDOWN_LIST),
  getCreditDetails: (creditId: string) =>
    ApiService.getData(
      `${INVOICE_URLS.CREDIT_NOTES_URLS.CREDIT_NOTE_DETAILS}${creditId}`
    ),
  editCreditNoteDetails: (creditId: string) =>
    ApiService.getData(
      `${INVOICE_URLS.CREDIT_NOTES_URLS.EDIT_CREDIT_NOTE_DETAILS}${creditId}`
    ),
  cancelCreditNote: (data: any) =>
    ApiService.putData(INVOICE_URLS.CREDIT_NOTES_URLS.CANCEL_CREDIT_NOTE, data),
  deleteCreditNote: (params: Record<string, any>) =>
    ApiService.deleteData(
      `${INVOICE_URLS.CREDIT_NOTES_URLS.DELETE_CREDIT_NOTE}`,
      {
        params,
      }
    ),
  downloadCreditNoteListExcelSheet: (params?: Record<string, any>) =>
    ApiService.request({
      method: 'GET',
      url: INVOICE_URLS.CREDIT_NOTES_URLS.EXPORT_TO_EXCEL,
      responseType: 'blob',
      params: params,
    }),
  downloadCreditNoteListPdf: (params?: Record<string, any>) =>
    ApiService.request({
      method: 'GET',
      url: INVOICE_URLS.CREDIT_NOTES_URLS.EXPORT_TO_PDF,
      responseType: 'blob',
      params: {
        ...params,
        userDate: new Date(),
      },
    }),
  changeCreditStatus: (data: Record<string, any>, creditId: string) =>
    ApiService.putData(
      `${INVOICE_URLS.CREDIT_NOTES_URLS.CHANGE_CREDIT_STATUS}${creditId}`,
      data
    ),
  getModyfiedUploadedCreditNoteData: (params: Record<string, string>) =>
    ApiService.postData(
      INVOICE_URLS.CREDIT_NOTES_URLS.GET_MODIFIED_UPLOADED_CREDIT_NOTE,
      null,
      { params }
    ),
  modifyUploadedCreditNote: (params: Record<string, string>) =>
    ApiService.postData(
      INVOICE_URLS.CREDIT_NOTES_URLS.MODIFY_UPLOADED_CREDIT_NOTE,
      null,
      { params }
    ),
};
