import {
  Autocomplete,
  Icon,
  InputAdornment,
  inputAdornmentClasses,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
/*import { DatePicker } from '@mui/x-date-pickers/DatePicker';*/
import {
  DatePicker,
  MobileDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
/*import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';*/
import dayjs from 'dayjs';
import _ from 'lodash';
import { ChangeEvent, FC, SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CalenderIcon from '../../assets/images/CalenderIcon';
import { FormField } from '../../constants/interfaces';
import { errorMessages } from '../../constants/registerConstants';
import { saveAppliedFilters } from '../../store/actions/listFiltersAction';
import CustomModal, { ButtonType } from '../customModal/CustomModal';
import Input from '../input/Input';
import { errorNotification } from '../notifyHelper';
import { enterKeyHelper } from '../../helpers/enterKeyHelper';

interface FilterModalProps {
  requestFrom: string;
  defaultParams?: Record<string, any>;
  closeModal: () => void;
  filterFields: FormField[];
  updateListAction: (dispatch: any) => void;
  filterAction?: any;
  isDateFilters?: boolean;
}

const FilterModal: FC<FilterModalProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState('');
  const {
    requestFrom,
    defaultParams,
    closeModal,
    filterFields,
    updateListAction,
    filterAction,
    isDateFilters,
  } = props;
  const dispatch = useDispatch();
  const allFilters: any = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );
  const moduleFilters = allFilters[requestFrom];
  const [tempFilters, setTempFilters] = useState<Record<string, any>>(
    moduleFilters ?? {}
  );
  const [modifiedFilters, setModifiedFilters] = useState<string[]>([]);
  const onResetFilters = () => {
    setTempFilters({});
    dispatch(updateListAction(defaultParams));
    filterAction?.forEach((action: () => void) => dispatch(action()));
    dispatch(saveAppliedFilters(requestFrom, {}));
    closeModal();
  };

  const onApplyFilters = () => {
    const finalFilters: Record<string, any> = {
      ...defaultParams,
      ...moduleFilters,
      ...tempFilters,
    };

    for (const key in finalFilters) {
      const value = finalFilters[key];
      if (Array.isArray(value)) {
        finalFilters[key] = value
          ?.map((val: Record<string, string>) => val.name ?? value)
          ?.join(',');
      } else if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        value !== null
      ) {
        finalFilters[key] = value?._id ?? value?.name ?? value;
      } else if (typeof value === 'string') {
        finalFilters[key] = value.toString().trim();
      }
    }
    if (!_.isEqual(moduleFilters, tempFilters)) {
      if (isDateFilters) {
        if (finalFilters.endDate) {
          if (finalFilters['startDate']) {
            dispatch(updateListAction(finalFilters));
            dispatch(
              saveAppliedFilters(requestFrom, {
                ...moduleFilters,
                ...tempFilters,
              })
            );
            closeModal();
          } else {
            errorNotification('Please provide start date');
          }
        } else {
          dispatch(updateListAction(finalFilters));
          dispatch(
            saveAppliedFilters(requestFrom, {
              ...moduleFilters,
              ...tempFilters,
            })
          );
          closeModal();
        }
      } else if (requestFrom === 'recipe') {
        let validated = true;
        if (finalFilters['numberOfPortionsTo']) {
          if (finalFilters['numberOfPortionsFrom']) {
            if (
              finalFilters['numberOfPortionsFrom'] >
              finalFilters['numberOfPortionsTo']
            ) {
              errorNotification(
                'The starting serving size range must be lower than the ending serving size range'
              );
              validated = false;
            }
          } else {
            validated = false;
            errorNotification("Please enter 'Serving Size From'");
          }
        }
        if (finalFilters['salePriceTo']) {
          if (finalFilters['salePriceFrom']) {
            if (finalFilters['salePriceFrom'] > finalFilters['salePriceTo']) {
              errorNotification(
                'The starting price range must be lower than the ending price range'
              );
              validated = false;
            }
          } else {
            validated = false;
            errorNotification("Please enter 'Price From'");
          }
        }
        if (validated) {
          dispatch(updateListAction(finalFilters));
          dispatch(
            saveAppliedFilters(requestFrom, {
              ...moduleFilters,
              ...tempFilters,
            })
          );
          closeModal();
        }
      } else {
        dispatch(updateListAction(finalFilters));
        dispatch(
          saveAppliedFilters(requestFrom, { ...moduleFilters, ...tempFilters })
        );
        closeModal();
      }
    } else {
      errorNotification(
        'Please select different filter, this filters are already applied'
      );
    }
  };

  const onCloseModal = () => {
    closeModal();
  };

  const filterModalButton: ButtonType[] = [
    {
      title: 'Reset',
      onClick: onResetFilters,
      color: 'secondary',
    },
    {
      title: 'Apply',
      onClick: onApplyFilters,
      color: 'primary',
    },
  ];

  const onAutocompleteChange = (
    name: string,
    event: SyntheticEvent,
    value: any
  ) => {
    setModifiedFilters([...modifiedFilters, name]);
    setTempFilters({
      ...tempFilters,
      [name]: value,
    });
  };

  const onInputChange = (
    input: Record<string, any>,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (input.type === 'numberOfPortions') {
      if (!event.target.value) {
        setTempFilters({
          ...tempFilters,
          [input.name]: undefined,
        });
        delete moduleFilters[input.name];
      } else {
        if (
          input.name === 'numberOfPortionsFrom' &&
          event.target.value > tempFilters['numberOfPortionsTo']
        ) {
          errorNotification(
            "Please enter 'Serving Size From' less than 'Serving Size To'"
          );
          setTempFilters({
            ...tempFilters,
            [input.name]: undefined,
          });
          delete moduleFilters[input.name];
        } else if (
          input.name === 'numberOfPortionsTo' &&
          event.target.value < tempFilters['numberOfPortionsFrom']
        ) {
          errorNotification(
            "Please enter 'Serving Size To' greater than 'Serving Size From'"
          );
          setTempFilters({
            ...tempFilters,
            [input.name]: undefined,
          });
          delete moduleFilters[input.name];
        } else {
          setModifiedFilters([...modifiedFilters, input.name]);
          setTempFilters({
            ...tempFilters,
            [input.name]: event.target.value,
          });
        }
      }
    } else if (input.type === 'salePrice') {
      if (!event.target.value) {
        setTempFilters({
          ...tempFilters,
          [input.name]: undefined,
        });
        delete moduleFilters[input.name];
      } else {
        if (
          input.name === 'salePriceFrom' &&
          event.target.value > tempFilters['salePriceTo']
        ) {
          errorNotification("Please enter 'Price From' less than 'Price To'");
          setTempFilters({
            ...tempFilters,
            [input.name]: undefined,
          });
          delete moduleFilters[input.name];
        } else if (
          input.name === 'salePriceTo' &&
          event.target.value < tempFilters['salePriceFrom']
        ) {
          errorNotification(
            "Please enter 'Price To' greater than 'Price From'"
          );
          setTempFilters({
            ...tempFilters,
            [input.name]: undefined,
          });
          delete moduleFilters[input.name];
        } else {
          setModifiedFilters([...modifiedFilters, input.name]);
          setTempFilters({
            ...tempFilters,
            [input.name]: event.target.value,
          });
        }
      }
    } else {
      if (!event.target.value) {
        setTempFilters({
          ...tempFilters,
          [input.name]: undefined,
        });
        delete moduleFilters[input.name];
      } else {
        setModifiedFilters([...modifiedFilters, input.name]);
        setTempFilters({
          ...tempFilters,
          [input.name]: event.target.value,
        });
      }
    }
  };

  const getComponentFromType = (input: Record<string, any>) => {
    let component = null;
    let dropdownValue = null;

    if (
      (requestFrom === 'purchaseChart' ||
        requestFrom === 'salesChart' ||
        requestFrom === 'costSaleChart') &&
      tempFilters.duration !== 'Custom' &&
      (input.name === 'startDate' || input.name === 'endDate')
    )
      return;

    if (input?.isMulti && !allFilters[requestFrom]?.[input?.name]?.length) {
      dropdownValue = [];
    } else {
      dropdownValue = allFilters[requestFrom]?.[input.name];
    }

    switch (input.type) {
      case 'autocomplete':
        component = (
          <Autocomplete
            key={input.name}
            disableCloseOnSelect={input?.isMulti}
            className="autocomplete"
            options={input?.options ?? []}
            multiple={input?.isMulti}
            defaultValue={dropdownValue}
            getOptionLabel={(option) => option?.name ?? option ?? ''}
            value={
              modifiedFilters?.includes(input.name)
                ? tempFilters?.[input.name]
                : moduleFilters?.[input.name]
            }
            onChange={(event, value) =>
              onAutocompleteChange(input.name, event, value)
            }
            onKeyUp={(event) => enterKeyHelper(event, onApplyFilters)}
            renderInput={(params) => (
              <Input
                variant="filled"
                {...params}
                placeholder={input.placeholder}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        );
        break;

      case 'date':
        const onChangeDate = (name: string, value: any) => {
          if (
            name === 'endDate' &&
            tempFilters?.startDate?.toString()?.trim()?.length &&
            new Date(tempFilters?.startDate) > new Date(value)
          ) {
            errorNotification(
              "Please select 'To Date' greater than 'From Date'"
            );
          } else if (
            name === 'startDate' &&
            tempFilters?.endDate?.toString()?.trim()?.length &&
            new Date(tempFilters?.endDate) < new Date(value)
          ) {
            errorNotification("Please select 'From Date' less than 'To Date'");
          } else {
            setModifiedFilters([...modifiedFilters, name]);
            setTempFilters({
              ...tempFilters,
              [name]: value,
            });
          }
        };

        component = (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label=""
              // open={key === input.name && open}
              // onClose={() => setOpen(false)}
              showToolbar={false}
              openTo="day"
              views={['year', 'month', 'day']}
              value={
                (modifiedFilters?.includes(input.name)
                  ? tempFilters?.[input.name]
                  : moduleFilters?.[input.name]) ?? null
              }
              inputFormat="dd MMMM yyyy"
              onChange={(e) => {
                setOpen(true);
                onChangeDate(input.name, dayjs(e).format('YYYY-MM-DD'));
              }}
              disableFuture={input?.disableFutureDate}
              onError={(e) => errorNotification('Please select Proper Date')}
              components={{ OpenPickerIcon: CalenderIcon }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position={'end'}
                    onClick={() => setOpen((e) => !e)}
                  >
                    <Icon>
                      <CalenderIcon />
                    </Icon>
                  </InputAdornment>
                ),
              }}
              renderInput={(params: any) => (
                <Input
                  variant="filled"
                  onClick={() => {
                    setKey(input.name);
                    setOpen(true);
                  }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'Select Date',
                    readOnly: true,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        );
        break;

      default:
        component = (
          <Input
            variant="filled"
            key={input.name}
            type={
              input.type === 'numberOfPortions' || input.type === 'salePrice'
                ? 'number'
                : 'text'
            }
            placeholder={input.placeholder}
            value={tempFilters?.[input.name] ?? moduleFilters?.[input.name]}
            onChange={(event) => onInputChange(input, event)}
            onKeyUp={(event) => enterKeyHelper(event, onApplyFilters)}
          />
        );
    }
    return (
      <>
        <div className={`filter-title ${input?.className ?? ''}`}>
          {input.label}
        </div>
        {component}
      </>
    );
  };

  return (
    <CustomModal
      header="Filter"
      className="filter-modal"
      headerClassName="center-title"
      buttons={filterModalButton}
      hideModal={onCloseModal}
    >
      <div>
        {filterFields?.map((field) => {
          if (field.type === 'rang') {
            return (
              <div className="range-fields-container">
                {field?.fields?.map((rangField: Record<string, any>) => (
                  <div>{getComponentFromType(rangField)}</div>
                ))}
              </div>
            );
          } else return getComponentFromType(field);
        })}
      </div>
    </CustomModal>
  );
};

export default FilterModal;
