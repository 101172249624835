import { REPORT_URL } from '../../../constants/urlConstants';
import ApiService from '../../apiService/apiService';

export const reconciledStatementsApiServices = {
  reconciledStatementsList: (params: Record<string, string | number>) =>
    ApiService.getData(
      REPORT_URL.RECONCILED_STATEMENTS_URL.GET_RECONCILED_STATEMENTS_LIST,
      { params }
    ),
  supplierListDropdown: () =>
    ApiService.getData(
      REPORT_URL.RECONCILED_STATEMENTS_URL.SUPPLIER_LIST_DROPDOWN
    ),
  downloadReconciledStatementPdf: (data: Record<string, string>) =>
    ApiService.request({
      method: 'get',
      url: REPORT_URL.RECONCILED_STATEMENTS_URL.EXPORT_TO_PDF,
      responseType: 'blob',
      params: data,
    }),
  downloadReconciledStatementExcel: (data: Record<string, string>) =>
    ApiService.request({
      method: 'get',
      url: REPORT_URL.RECONCILED_STATEMENTS_URL.EXPORT_TO_EXCEL,
      responseType: 'blob',
      params: data,
    }),
};
