export const subscriberIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M14.6523 16.75V15.25C14.6523 14.4544 14.3363 13.6913 13.7737 13.1287C13.2111 12.5661 12.448 12.25 11.6523 12.25H5.65234C4.85669 12.25 4.09363 12.5661 3.53102 13.1287C2.96841 13.6913 2.65234 14.4544 2.65234 15.25V16.75"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.65234 9.25C10.3092 9.25 11.6523 7.90685 11.6523 6.25C11.6523 4.59315 10.3092 3.25 8.65234 3.25C6.99549 3.25 5.65234 4.59315 5.65234 6.25C5.65234 7.90685 6.99549 9.25 8.65234 9.25Z"
      stroke="#666666"
      strokeWidth="1.3"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.1523 16.7499V15.2499C19.1518 14.5852 18.9306 13.9395 18.5234 13.4141C18.1161 12.8888 17.5459 12.5136 16.9023 12.3474"
      stroke="#666666"
      strokeWidth="1.3"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.9023 3.34741C14.5477 3.51264 15.1196 3.88794 15.5281 4.41414C15.9365 4.94035 16.1582 5.58753 16.1582 6.25366C16.1582 6.91979 15.9365 7.56697 15.5281 8.09318C15.1196 8.61939 14.5477 8.99469 13.9023 9.15991"
      stroke="#666666"
      strokeWidth="1.3"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
