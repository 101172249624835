import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { downloadAll } from '../../../helpers/downloadHelper';
import { reconciledStatementsApiServices } from '../../../services/pageServices/reportServices/reconciledStatementsApiServices';
import { RECONCILED_STATEMENTS_REDUX_CONSTANTS } from '../../reduxConstants/reportReduxConstants/reconciledStatementsreduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const getReconciledStatementsList = (
  params: Record<string, string | number>
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('reconciledStatementsListLoaderAction');
      const response =
        await reconciledStatementsApiServices.reconciledStatementsList(params);
      if (response.status === 200) {
        dispatch({
          type: RECONCILED_STATEMENTS_REDUX_CONSTANTS.GET_RECONCILED_STATEMENTS_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('reconciledStatementsListLoaderAction');
    }
  };
};

export const supplierListDropdownAction = () => {
  return async (dispatch: any) => {
    try {
      const response =
        await reconciledStatementsApiServices.supplierListDropdown();
      if (response.status === 200) {
        dispatch({
          type: RECONCILED_STATEMENTS_REDUX_CONSTANTS.GET_SUPPLIER_LIST_DROPDOWN,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const ReconciledStatementExportToPdf = (data: Record<string, any>) => {
  return async () => {
    try {
      const response =
        await reconciledStatementsApiServices.downloadReconciledStatementPdf(
          data
        );
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const ReconciledStatementExportToExcel = (data: Record<string, any>) => {
  return async () => {
    try {
      const response =
        await reconciledStatementsApiServices.downloadReconciledStatementExcel(
          data
        );
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
