import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  createFilterOptions,
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal, {
  ButtonType,
} from '../../../../components/customModal/CustomModal';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import Input from '../../../../components/input/Input';
import {
  getInventoryProductList,
  mapperDataChange,
  onChangeUpdateStatusDataAction,
  resetUpdateStatusDataAction,
} from '../../../../store/actions/invoiceActions/invoiceActions';
import { inventoryProductValidation } from './inventoryProductvalidation';
import { useHistory, useParams } from 'react-router';

interface mapperModalProps {
  setMapperModal: (val: boolean) => void;
}

const InventoryProductMappingModal: FC<mapperModalProps> = (props) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const filter = createFilterOptions<any>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { invoiceId } = useParams<Record<string, string>>();

  const { setMapperModal } = props;
  const [expanded, setExpanded] = useState<string | false>(false);
  const [inventoryProductsOptions, setInventoryProductsOptions] = useState<
    Record<string, string>[]
  >([]);

  const { mapperData, orderListDropdown, inventoryProducts, updateStatus } =
    useSelector(
      ({ invoiceReducer }: Record<string, any>) => invoiceReducer ?? {}
    );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onSave = () => {
    setIsSubmitted(true);

    inventoryProductValidation(
      dispatch,
      mapperData,
      updateStatus,
      true,
      'onSubmit',
      undefined,
      history,
      invoiceId
    );
  };

  const modalButtons: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setMapperModal(false);
        dispatch(resetUpdateStatusDataAction());
      },
      color: 'secondary',
    },
    {
      title: 'Save',
      onClick: () => onSave(),
      color: 'primary',
    },
  ];

  const handleOnChange = async (
    e: any,
    key: string,
    productID: string,
    value?: any
  ) => {
    let inventoryValue = value;
    if (key === 'inventoryName') {
      inventoryValue = value?.inputValue ?? value;
      value.inputValue &&
        setInventoryProductsOptions((prev) => [
          ...prev,
          { name: value?.inputValue },
        ]);
    } else if (key === 'weight') {
      inventoryValue = e.target.value;
    }

    await dispatch(mapperDataChange(productID, key, inventoryValue));

    await inventoryProductValidation(
      dispatch,
      mapperData,
      updateStatus,
      isSubmitted,
      'onBlur',
      key,
      history,
      invoiceId,
      productID
    );
  };

  const onInputChange = (name: string, value: any) => {
    dispatch(onChangeUpdateStatusDataAction(name, value));

    inventoryProductValidation(
      dispatch,
      mapperData,
      updateStatus,
      isSubmitted,
      'onBlur',
      name,
      history,
      invoiceId
    );
  };

  useEffect(() => {
    setInventoryProductsOptions([...inventoryProducts]);
  }, [inventoryProducts]);

  useEffect(() => {
    dispatch(getInventoryProductList());
  }, []);

  return (
    <CustomModal
      header=" Product Additional Details"
      buttons={modalButtons}
      className="confirmation-modal mapper-modal"
      headerClassName="confirmation-modal-header"
      bodyClassName="mapper-modal-body "
      footerClassName="confirmation-modal-footer"
      hideModal={() => {
        setMapperModal(false);
        dispatch(resetUpdateStatusDataAction());
      }}
    >
      <div className="oreder-number-modal-body ">
        {/* {mapperData.length > 0 && (
          <div>
            <div className="details-block-title">Inventory Product Mapping</div>
            <div className="mapper-accordion">
              {mapperData?.map((product: Record<string, any>) => (
                <Accordion
                  expanded={expanded === product.name}
                  onChange={handleChange(product?.name)}
                  className={
                    product?.errors && Object.keys(product?.errors).length > 0
                      ? 'mapper-error-accordion'
                      : ''
                  }
                >
                  <AccordionSummary expandIcon={<ArrowDropDownRoundedIcon />}>
                    <Typography>{product?.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <div>
                      <div className="form-field-name">
                        <div className="form-field-name">
                          Inventory Product Name
                        </div>
                        <div className="required-sign">*</div>
                      </div>
                      <FormControl
                        className={`input-field ${
                          product?.errors?.inventoryName
                            ? 'input-with-error'
                            : ''
                        }`}
                      >
                        <Autocomplete
                          key={Math.random()}
                          placeholder="Select Inventory Product Name"
                          onChange={(event, newValue) =>
                            handleOnChange(
                              event,
                              'inventoryName',
                              product?.productId,
                              newValue
                            )
                          }
                          options={inventoryProductsOptions}
                          freeSolo
                          getOptionLabel={(option) =>
                            option?.name ?? option ?? ''
                          }
                          value={product?.inventoryName}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option: any) => inputValue === option?.name
                            );
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                inputValue,
                                name: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          className="autocomplete"
                          renderInput={(params) => (
                            <Input
                              variant="filled"
                              {...params}
                              placeholder="Select Inventory Product Name"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="input-with-error">
                          {product?.errors?.inventoryName}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div>
                      <div className="form-field-name">
                        <div className="form-field-name">Product UOM</div>
                      </div>
                      <FormControl className="input-field ">
                        <Input
                          variant="filled"
                          className={`input-field `}
                          value={product.unit}
                          type="text"
                          disabled
                        />
                      </FormControl>
                    </div>
                    <div>
                      <div className="form-field-name">
                        <div className="form-field-name">
                          Inventory Product UOM
                        </div>
                        <div className="required-sign">*</div>
                      </div>
                      <FormControl
                        className={`input-field ${
                          product?.errors?.inventoryUOM
                            ? 'input-with-error'
                            : ''
                        }`}
                      >
                        <Autocomplete
                          key={Math.random()}
                          placeholder="Select Inventory Product UOM"
                          onChange={(event, value) =>
                            handleOnChange(
                              event,
                              'inventoryUOM',
                              product?.productId,
                              value
                            )
                          }
                          freeSolo
                          options={['Kilogram', 'Litre']}
                          value={product?.inventoryUOM}
                          className="autocomplete"
                          renderInput={(params) => (
                            <Input
                              variant="filled"
                              {...params}
                              placeholder="Select Inventory Product UOM"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="input-with-error">
                          {product?.errors?.inventoryUOM}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div>
                      <div className="form-field-name">
                        <div className="form-field-name">{`weight / ${product.unit}`}</div>
                        <div className="required-sign">*</div>
                      </div>
                      <FormControl
                        className={`input-field ${
                          product?.errors?.weight ? 'input-with-error' : ''
                        }`}
                      >
                        <Input
                          variant="filled"
                          placeholder="Enter Weight"
                          className={`input-field `}
                          name="weight"
                          value={product?.weight}
                          type="text"
                          helperText={product?.errors?.weight}
                          onChange={(e) =>
                            handleOnChange(e, 'weight', product?.productId)
                          }
                        />
                      </FormControl>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        )} */}
        <div className="default-gst-input">
          <div className="form-field-name">
            <div className="form-field-name">Select Order Number</div>
          </div>
          <FormControl
            className={`${
              updateStatus?.errors?.orderId ? 'input-with-error' : ''
            }`}
          >
            <Autocomplete
              key={Math.random()}
              onChange={(event, value) => onInputChange('orderId', value)}
              options={orderListDropdown}
              getOptionLabel={(option: any) => option?.name ?? option ?? ''}
              value={updateStatus?.orderId}
              className="autocomplete"
              renderInput={(params) => (
                <Input
                  variant="filled"
                  {...params}
                  placeholder={'Select Order Number Matching Supplier'}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <FormHelperText className="input-with-error">
              {updateStatus?.errors?.orderId}
            </FormHelperText>
          </FormControl>
        </div>
        <div className="default-gst-input">
          <InputLabel className="add-default-gst-label">
            Delivered by
          </InputLabel>
          <FormControl
            className={`${
              updateStatus?.errors?.deliveredBy ? 'input-with-error' : ''
            }`}
          >
            <Input
              variant="filled"
              placeholder="Enter Delivered by"
              className={`input-field`}
              name="deliveredBy"
              value={updateStatus?.deliveredBy}
              type="text"
              onChange={(event) =>
                onInputChange('deliveredBy', event?.target.value)
              }
              helperText={updateStatus?.errors?.deliveredBy}
            />
          </FormControl>
        </div>
      </div>
    </CustomModal>
  );
};

export default InventoryProductMappingModal;
