import _ from 'lodash';
import {
  ABN_REGEX,
  ADDRESS_SIGN_VALIDATION_IN_EMAIL,
  ALPHA_HYPHEN_REGEX,
  ALPHA_HYPHEN_WITH_MAXIMUM_LENGTH,
  ALPHA_HYPHEN_WITH_MINIMUM_LENGTH,
  ALPHA_REGEX,
  ALPHA_WITH_MAXIMUM_LENGTH,
  ALPHA_WITH_MINIMUM_LENGTH,
  EMAIL_ADDRESS_REGEX,
  MOBILE_NUMBER_REGEX,
  POSTAL_CODE_REGEX,
} from '../../../constants/regexConstants';
import { errorMessages } from '../../../constants/registerConstants';
import { focusHelper } from '../../../helpers/focusHelper';
import {
  onChangeRegisterData,
  validateAbnAndVenueAction,
} from '../../../store/actions/registerActions';
import { PASSWORD_REGEX } from './../../../helpers/validationHelper';
import { onRegister } from './../../../store/actions/registerActions';
export const handleRegisterValidation = async (
  stepData: Record<string, any>,
  currentStep: number,
  dispatch: any,
  data: Record<string, any>,
  history: any // remove
) => {
  const {
    firstName,
    lastName,
    password,
    reTypePassword,
    state,
    city,
    postalCode,
    abn,
    teamMemberFirstName,
    teamMemberLastName,
    email,
    teamMemberEmail,
    contactNumber,
    tradingName,
    teamMemberContactNumber,
  } = stepData;

  let validated: boolean = true;
  let errors: { [key: string]: string | undefined } = {};
  const emptyFields = Object.keys(stepData).filter(
    (key) =>
      stepData?.[key] === '' || !stepData?.[key]?.toString()?.trim()?.length
  );

  if (currentStep === 1 && tradingName !== '' && abn !== '') {
    const companyDetails = await dispatch(validateAbnAndVenueAction(abn));
    if (
      (companyDetails?.entityName || companyDetails?.tradingName) !==
      tradingName
    ) {
      validated = false;
      errors.tradingName = `${errorMessages.tradingName}`;
    }
  }

  if (currentStep === 2 && data?.inviteMemberLater) {
    const indexOfMemberFirstName = emptyFields.indexOf('teamMemberFirstName');
    emptyFields.splice(indexOfMemberFirstName, 1);
    const indexOfMemberLastName = emptyFields.indexOf('teamMemberLastName');
    emptyFields.splice(indexOfMemberLastName, 1);
    const indexOfMemberRole = emptyFields.indexOf('teamMemberRole');
    emptyFields.splice(indexOfMemberRole, 1);
    const indexOfMemberEmail = emptyFields.indexOf('teamMemberEmail');
    emptyFields.splice(indexOfMemberEmail, 1);
    const indexOfMemberContactNumber = emptyFields.indexOf(
      'teamMemberContactNumber'
    );
    emptyFields.splice(indexOfMemberContactNumber, 1);
    // errors['teamMemberFirstName'] = undefined;
    // errors['teamMemberLastName'] = undefined;
    // errors['teamMemberRole'] = undefined;
    // errors['teamMemberEmail'] = undefined;
    // errors['teamMemberContactNumber'] = undefined;
  }

  emptyFields.forEach((emptyField: string) => {
    errors[emptyField] = `${errorMessages[emptyField]}`;
  });
  if (emptyFields.length) {
    validated = false;
  }
  if (firstName?.toString()?.trim().length) {
    if (!ALPHA_REGEX.test(firstName)) {
      validated = false;
      errors.firstName = `Only enter letters. Not a valid entry`;
    } else if (!ALPHA_WITH_MINIMUM_LENGTH.test(firstName)) {
      validated = false;
      errors.firstName = `Must contain at least two letters`;
    } else if (!ALPHA_WITH_MAXIMUM_LENGTH.test(firstName)) {
      validated = false;
      errors.firstName = `Maximum 50 characters allowed`;
    }
  }
  if (lastName?.toString()?.trim().length) {
    if (!ALPHA_HYPHEN_REGEX.test(lastName)) {
      validated = false;
      errors.lastName = `Only enter letters and hyphen. Not a valid entry`;
    } else if (!ALPHA_HYPHEN_WITH_MINIMUM_LENGTH.test(lastName)) {
      validated = false;
      errors.lastName = `Must contain at least two letters`;
    } else if (!ALPHA_HYPHEN_WITH_MAXIMUM_LENGTH.test(lastName)) {
      validated = false;
      errors.lastName = `Maximum 50 characters allowed`;
    }
  }
  if (
    reTypePassword?.toString()?.trim()?.length &&
    password?.toString()?.trim() !== reTypePassword?.toString()?.trim()
  ) {
    validated = false;
    errors.reTypePassword = 'Confirm Password and Password should be same';
  }
  if (password && !PASSWORD_REGEX.test(password?.toString().trim())) {
    validated = false;
    errors.password = 'Invalid Password';
  }
  if (email?.toString()?.trim().length) {
    if (!ADDRESS_SIGN_VALIDATION_IN_EMAIL.test(email)) {
      validated = false;
      errors.email = `Email address is missing the @ symbol`;
    } else if (!EMAIL_ADDRESS_REGEX.test(email)) {
      validated = false;
      errors.email = 'Not a valid email address';
    }
  }
  if (
    postalCode?.toString()?.trim().length &&
    !POSTAL_CODE_REGEX.test(postalCode)
  ) {
    validated = false;
    errors.postalCode = `${errorMessages.postalCode}`;
  }
  if (city?.toString()?.trim().length && !ALPHA_REGEX.test(city)) {
    validated = false;
    errors.city = `${errorMessages.city}`;
  }
  if (abn?.toString()?.trim().length && !ABN_REGEX.test(abn)) {
    validated = false;
    errors.abn = `${errorMessages.abn}`;
  }
  if (teamMemberFirstName?.toString()?.trim().length) {
    if (!ALPHA_REGEX.test(teamMemberFirstName)) {
      validated = false;
      errors.teamMemberFirstName = `Only enter letters. Not a valid entry`;
    } else if (!ALPHA_WITH_MINIMUM_LENGTH.test(teamMemberFirstName)) {
      validated = false;
      errors.teamMemberFirstName = `Must contain at least two letters`;
    } else if (!ALPHA_WITH_MAXIMUM_LENGTH.test(teamMemberFirstName)) {
      validated = false;
      errors.teamMemberFirstName = `Maximum 50 characters allowed`;
    }
  }
  if (teamMemberLastName?.toString()?.trim().length) {
    if (!ALPHA_HYPHEN_REGEX.test(teamMemberLastName)) {
      validated = false;
      errors.teamMemberLastName = `Only enter letters and hyphen. Not a valid entry`;
    } else if (!ALPHA_HYPHEN_WITH_MINIMUM_LENGTH.test(teamMemberLastName)) {
      validated = false;
      errors.teamMemberLastName = `Must contain at least two letters`;
    } else if (!ALPHA_HYPHEN_WITH_MAXIMUM_LENGTH.test(teamMemberLastName)) {
      validated = false;
      errors.teamMemberLastName = `Maximum 50 characters allowed`;
    }
  }
  if (teamMemberEmail?.toString()?.trim().length) {
    if (!ADDRESS_SIGN_VALIDATION_IN_EMAIL.test(teamMemberEmail)) {
      validated = false;
      errors.teamMemberEmail = `Email address is missing the @ symbol`;
    } else if (!EMAIL_ADDRESS_REGEX.test(teamMemberEmail)) {
      validated = false;
      errors.teamMemberEmail = `Please enter valid email`;
    }
  }
  if (!data?.isReadPolicyAndTerms) {
    validated = false;
    errors.isReadPolicyAndTerms =
      'Please accept our legal policy & terms to proceed further';
  }
  dispatch(onChangeRegisterData('errors', errors));
  if (
    !_.isEmpty(errors) &&
    !Object.values(errors).every((e) => e === undefined)
  ) {
    focusHelper(Object.keys(errors)?.[0]);
  }
  if (validated && currentStep === 2 && !emptyFields.length) {
    if (data.hasOwnProperty('errors')) {
      delete data.errors;
    }
    const CryptoJS = require('crypto-js');
    var hash = CryptoJS.SHA256(data.password);
    const finalData = {
      tradingName: data.tradingName.toString().trim(),
      firstName: data.firstName.toString().trim(),
      lastName: data.lastName.toString().trim(),
      email: data.email.toString().trim().toLowerCase(),
      contactNumber: data.contactNumber.toString().trim(),
      password: hash.toString(),
      isReadPolicyAndTerms: data.isReadPolicyAndTerms,
      country: data.country.name.toString().trim(),
      state: data.state.name ?? data.state.toString().trim(),
      city: data.city.toString().trim(),
      postalCode: data.postalCode.toString().trim(),
      address: data.address.toString().trim(),
      abn: data.abn.replace(/ +/g, ''),
      role: data.role.toString().trim(),
      teamMemberFirstName: data?.teamMemberFirstName?.toString()?.trim(),
      teamMemberLastName: data?.teamMemberLastName?.toString()?.trim(),
      venue: data.venue.toString().trim(),
      teamMemberEmail: data?.teamMemberEmail?.toString()?.trim().toLowerCase(),
      numberOfEmployee: data?.numberOfEmployee._id,
      teamMemberRole: data?.teamMemberRole?.toString()?.trim(),
      teamMemberContactNumber: data?.teamMemberContactNumber
        ?.toString()
        ?.trim(),
      inviteMemberLater: data?.inviteMemberLater,
      timeZoneString: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneOffSet: new Date().getTimezoneOffset() / 60,
    };
    dispatch(onRegister(finalData, history));
  }
  return validated;
};
