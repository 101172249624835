import { Dispatch } from 'react';
import { successNotification } from '../../../components/notifyHelper';
import { downloadAll } from '../../../helpers/downloadHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { invoiceApiServices } from '../../../services/pageServices/invoiceServices/invoiceApiServices';
import { INVOICE_REDUX_CONSTANTS } from '../../reduxConstants/invoiceReduxConstants/invoiceReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';
import { getAuthTokenFromLocalStorage } from '../../../helpers/localstoragehelper';

export const getInvoiceListAction = (params: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('invoiceListLoaderAction');
      const response = await invoiceApiServices.invoiceList(params);
      if (response.status === 200) {
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.INVOICE_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('invoiceListLoaderAction');
    }
  };
};

export const getInvoiceDetailsByIdAction = (invoiceId: string) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('invoiceDetailsLoaderAction');
      const response = await invoiceApiServices.invoiceDetailsById(invoiceId);
      if (response.status === 200) {
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.GET_INVOICE_DETAILS_BY_ID,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('invoiceDetailsLoaderAction');
    }
  };
};

export const getInvoiceDetailsByOrderId = (orderId: string) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('invoiceDetailsFromOrderLoaderAction');
      const response = await invoiceApiServices.invoiceDetailsByOrderId(
        orderId
      );
      if (response.status === 200) {
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.GET_INVOICE_DETAILS_BY_ID,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('invoiceDetailsFromOrderLoaderAction');
    }
  };
};

export const getOrderDropdownDataAction = (supplierId: string) => {
  return async (dispatch: any) => {
    try {
      const params = {
        supplierId,
      };
      const response = await invoiceApiServices.orderListDropdown(params);
      if (response.status === 200) {
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.GET_ORDER_DROPDOWN_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onChangeUpdateStatusDataAction = (name: string, value: string) => {
  return (dispatch: any) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.ON_CHANGE_UPDATE_STATUS_DATA,
      data: { name, value },
    });
  };
};

export const resetUpdateStatusDataAction = () => {
  return (dispatch: any) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.RESET_UPDATE_STATUS_DATA,
    });
  };
};

export const invoiceAcceptAction = (
  invoiceId: string,
  data: Record<string, any>,
  history: any
) => {
  return async () => {
    try {
      const response = await invoiceApiServices.acceptInvoice(invoiceId, data);
      if (response.status === 200) {
        successNotification(response.data.message);
        history.push(ROUTE_CONSTANTS_VARIABLE.INVOICE);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const invoicePendingAction = (invoiceId: string, history: any) => {
  return async () => {
    try {
      const response = await invoiceApiServices.pendingInvoice(invoiceId);
      if (response.status === 200) {
        successNotification(response.data.message);
        history.push(ROUTE_CONSTANTS_VARIABLE.INVOICE);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const deleteInvoice = (ids: string, cb: () => void) => {
  return async (dispatch: any) => {
    try {
      const params = {
        invoiceIds: ids,
      };
      const response = await invoiceApiServices.deleteInvoice(params);
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Record deleted successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getSupplierDropdownInInvoice = () => {
  return async (dispatch: Dispatch<Record<string, string>>) => {
    try {
      const response = await invoiceApiServices.supplierDropdownList();
      if (response.status === 200) {
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.GET_SUPPLIER_DROPDOWN,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getUserDropdownInInvoice = () => {
  return async (dispatch: Dispatch<Record<string, string>>) => {
    try {
      const response = await invoiceApiServices.userDropdownList();
      if (response.status === 200) {
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.GET_USER_DROPDOWN,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const resetInvoiceDetails = () => {
  return (dispatch: any) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.RESET_INVOICE_DETAILS,
    });
  };
};

export const modifyInvoiceAction = (
  incoiceId: string,
  data: Record<string, string>
) => {
  return async () => {
    try {
      const response = await invoiceApiServices.modifyInvoice(incoiceId, data);
      if (response.status === 200) {
        successNotification(response.data.data.message);
        window.open(response.data.data.url, '_blank');
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getModifiedInvoiceAction = (
  incoiceId: string,
  documentId: string
) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('getModifiedDataLoaderAction');
      const data = {
        documentId,
      };
      const response = await invoiceApiServices.getModyfiedData(
        incoiceId,
        data
      );
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getModifiedDataLoaderAction');
    }
  };
};

export const exportInvoicesExcelsheet = (data: Record<string, string>) => {
  return async () => {
    try {
      const response = await invoiceApiServices.downloadInvoicesExcelSheet(
        data
      );
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportInvoicesPdf = (data: Record<string, any>) => {
  return async () => {
    try {
      const response = await invoiceApiServices.downloadInvoicesPdf(data);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const modifyUploadedInvoiceAction = (data: Record<string, string>) => {
  return async () => {
    try {
      const response = await invoiceApiServices.modifyUploadedInvoice(data);
      if (response.status === 200) {
        window.open(response.data.data.url, '_blank');
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getModifiedUploadedInvoiceAction = (
  data: Record<string, string>
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('getUploadedModifiedDataLoaderAction');
      const response = await invoiceApiServices.getModyfiedUploadedData(data);
      if (response.status === 200) {
        successNotification(response.data.data.message);
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.UPLOAD_INVOICE,
          data: { ...response.data.data },
        });
        return response.data.data;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getUploadedModifiedDataLoaderAction');
    }
  };
};

export const syncInvoiceAction = (invoiceId: string) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest(`${invoiceId}Sync`);
      startGeneralLoaderOnRequest('manualSyncInvoiceLoaderAction');
      const response = await invoiceApiServices.syncInvoice(invoiceId);
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(`${invoiceId}Sync`);
      stopGeneralLoaderOnSuccessOrFail('manualSyncInvoiceLoaderAction');
    }
  };
};

export const getSupplierDropdownAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await invoiceApiServices.supplierDropdown();
      if (response.status === 200)
        [
          dispatch({
            type: INVOICE_REDUX_CONSTANTS.GET_SUPPLIER_DROPDOWN_LIST,
            data: response.data.data,
          }),
        ];
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getProductDropdownAction = (supplierId: string) => {
  return async (dispatch: any) => {
    try {
      const response = await invoiceApiServices.productDropdown({ supplierId });
      if (response.status === 200) {
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.GET_PRODUCT_DROPDOWN_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onChangeInvoiceData = (
  fieldName: string,
  fieldValue:
    | string
    | Record<string, string | undefined>
    | Record<string, string>[]
    | undefined
    | null
    | number
) => {
  return (dispatch: any) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.ON_CHANGE_INVOICE_DATA,
      data: {
        fieldName,
        fieldValue,
      },
    });
  };
};

export const uploadCreateInvoice = (
  data: FormData,
  config: Record<string, Record<string, string>>
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('browseFileLoaderAction');
      const response = await invoiceApiServices.uploadCreateInvoice(
        data,
        config
      );
      if (response?.status === 200) {
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.UPLOAD_CREATE_INVOICE,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('browseFileLoaderAction');
    }
  };
};

export const addInvoiceAction = (data: Record<string, any>) => {
  return async () => {
    try {
      startGeneralLoaderOnRequest('addInvoiceLoaderAction');
      const response = await invoiceApiServices.addInvoice(data);
      if (response.status === 201) {
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('addInvoiceLoaderAction');
    }
  };
};

export const deleteProductAction = (productId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.DELETE_INVOICE_PRODUCT,
      data: productId,
    });
  };
};

export const resetAddInvoiceAction = () => {
  return (dispatch: any) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.RESET_ADD_INVOICE,
    });
  };
};

export const onChangeQuantityAction = (data: Record<string, string>) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.ON_CHANGE_QUANTITY,
      data,
    });
  };
};

export const onChangeQuantityAmountAction = (data: Record<string, string>) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.ON_CHANGE_QUANTITY_AMOUNT,
      data,
    });
  };
};

export const onInvoiceProductPriceChange = (
  data: Record<string, string | number>
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.ON_INVOICE_INPUT_CHANGE,
      data,
    });
  };
};

export const getAddEditInvoiceDetails = (invoiceId: string) => {
  return async (dispatch: any) => {
    try {
      const response = await invoiceApiServices.getEditInvoiceData(invoiceId);
      if (response.status === 200) {
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.GET_EDIT_INVOICE_DATA,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const updateInvoiceAction = (
  invoiceId: string,
  data: Record<string, any>,
  cb: () => void
) => {
  return async () => {
    try {
      const response = await invoiceApiServices.updateInvoice(invoiceId, data);
      if (response.status === 200) {
        successNotification(
          `${data?.invoiceNumber}'s record update successfully`
        );
        cb();
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const autoSyncAction = (autoSync: boolean) => {
  return async () => {
    startGeneralLoaderOnRequest('invoiceAutosyncLoaderAction');
    try {
      const response = await invoiceApiServices.autoSync({ autoSync });
      if (response.status === 200) {
        successNotification(response.data.message);
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('invoiceAutosyncLoaderAction');
    }
  };
};

export const removeUploadedInvoice = () => {
  return (dispatch: any) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.REMOVE_UPLOADED_INVOICE,
    });
  };
};

export const getMapperDataAction = (invoiceId: string) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('getMapperDataloaderAction');
      const response = await invoiceApiServices.getMapperData(invoiceId);
      if (response.status === 200) {
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.GET_MAPPER_DATA,
          data: response.data.data,
        });
        return true;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getMapperDataloaderAction');
    }
  };
};

export const getInventoryProductList = () => {
  return async (dispatch: any) => {
    try {
      const response = await invoiceApiServices.getInventoryProductList();
      if (response.status === 200) {
        dispatch({
          type: INVOICE_REDUX_CONSTANTS.GET_INVENTORY_PRODUCTS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const mapperDataChange = (
  productID: string,
  key: string,
  inventoryValue: any
) => {
  return (dispatch: any) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.ON_MAPPER_DATA_CHANGE,
      data: { productID, key, inventoryValue },
    });
  };
};

export const getUploadedInvoiceResponse = (data: Record<string, any>) => {
  return (dispatch: any) => {
    dispatch({
      type: INVOICE_REDUX_CONSTANTS.UPLOAD_INVOICE,
      data,
    });
  };
};

export const fetchAttachment = () => {
  return async (dispatch: any) => {
    try {
      const token = getAuthTokenFromLocalStorage();
      const config = {
        headers: {
          'Content-Type': 'application/json',
          authorization: `${token}`,
        },
      };
      const response = await invoiceApiServices.fetchAttachment(config);
      if (response.status === 200) {
        successNotification(
          response?.data?.message ||
            `${response.data.emailFetchOption} is selected for Invoice Upload `
        );
      } else {
        successNotification(response?.data?.message || `Data Uploading Failed`);
      }
    } catch (error) {}
  };
};

export const addImapConfig = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token = getAuthTokenFromLocalStorage();
      const config = {
        headers: {
          'Content-Type': 'application/json',
          authorization: `${token}`,
        },
      };
      const response = await invoiceApiServices.addImapConfig(data, config);
      if (response.status === 200) {
        successNotification(
          response?.data?.message || `Data Added Successfully `
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};
