import { successNotification } from '../../../components/notifyHelper';
import { downloadAll } from '../../../helpers/downloadHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { reconcileApiService } from '../../../services/pageServices/invoiceServices/reconcileApiService';
import { RECONCILE_REDUX_CONSTANTS } from '../../reduxConstants/invoiceReduxConstants/reconcileReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const getReconcileStatementAction = (params: Record<string, string>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('reconciledStatementListLoaderAction');
      const response = await reconcileApiService.reconcileList(params);
      if (response.status === 200) {
        dispatch({
          type: RECONCILE_REDUX_CONSTANTS.GET_RECONCILE_STATEMENT_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('reconciledStatementListLoaderAction');
    }
  };
};

export const getSupplierListaction = () => {
  return async (dispatch: any) => {
    try {
      const response = await reconcileApiService.supplierLst();
      if (response.status === 200) {
        dispatch({
          type: RECONCILE_REDUX_CONSTANTS.GET_SUPPLIER_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getStatementsAction = (supplierId: string) => {
  return async (dispatch: any) => {
    try {
      const response = await reconcileApiService.statementList({ supplierId });
      if (response.status === 200) {
        dispatch({
          type: RECONCILE_REDUX_CONSTANTS.GET_STATEMENT_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getInvoiceIdsAction = (supplierId: string) => {
  return async (dispatch: any) => {
    try {
      const response = await reconcileApiService.invoiceIdList({ supplierId });
      if (response.status === 200) {
        dispatch({
          type: RECONCILE_REDUX_CONSTANTS.GET_INVOICE_ID_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onChangeInvoiceAcion = (checkedIds: string[]) => {
  return (dispatch: any) => {
    dispatch({
      type: RECONCILE_REDUX_CONSTANTS.ON_CHANGE_INVOICE_CHECKBOX,
      data: checkedIds,
    });
  };
};

export const reconcileStatementAction = (
  data: Record<string, Record<string, string | boolean | number>>,
  supplierId: string
) => {
  return async () => {
    try {
      const response = await reconcileApiService.updateStatus(data, supplierId);
      if (response.status === 200) {
        successNotification(response.data.message);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const onAdjustmentNoteChange = (
  name: string,
  value: string | Record<string, string | undefined> | undefined | null
) => {
  return (dispatch: any) => {
    dispatch({
      type: RECONCILE_REDUX_CONSTANTS.ON_CHANGE_ADJUSTMENT_NOTE,
      data: { name, value },
    });
  };
};

export const addAdjustmentNoteAction = (
  data: Record<string, string | number>,
  supplierId: string
) => {
  return async () => {
    try {
      const response = await reconcileApiService.addAdjustmentNote(
        data,
        supplierId
      );
      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const resetAdjustmentNoteDetailsAction = () => {
  return (dispatch: any) => {
    dispatch({
      type: RECONCILE_REDUX_CONSTANTS.RESET_ADJUSTMENTNOTE_DETAILS,
    });
  };
};

export const exportToExcelReconcileAction = (data: Record<string, string>) => {
  return async () => {
    try {
      const response = await reconcileApiService.exportToExcelReconcile(data);
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportToPdfReconcileAction = (data: Record<string, any>) => {
  return async () => {
    try {
      const response = await reconcileApiService.exportToPdfReconcile(data);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
