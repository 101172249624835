import { successNotification } from '../../../components/notifyHelper';
import { downloadAll } from '../../../helpers/downloadHelper';
import { displayErrors } from '../../../helpers/errorNotifyHelper';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { creditNotesApiServices } from '../../../services/pageServices/invoiceServices/creditNotesApiServices';
import { CREDIT_NOTES_REDUX_CONSTANTS } from '../../reduxConstants/invoiceReduxConstants/creditNotesReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../generalLoaderAction';

export const uploadPhotoForCreditAction = (
  data: FormData,
  config: Record<string, Record<string, string>>,
  URLs: string[]
) => {
  return async (dispatch: any) => {
    try {
      const response = await creditNotesApiServices.uploadPhotoForCredit(
        data,
        config
      );
      if (response?.status === 200) {
        dispatch({
          type: CREDIT_NOTES_REDUX_CONSTANTS.UPLOAD_PHOTO_FOR_CREDIT,
          data: [...URLs, response.data.data],
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const onChangeCreditNoteData = (
  name: string,
  value: string | Record<string, string | undefined> | undefined | null
) => {
  return (dispatch: any) => {
    dispatch({
      type: CREDIT_NOTES_REDUX_CONSTANTS.ON_CHANGE_CREDIT_NOTE_DATA,
      data: {
        name,
        value,
      },
    });
  };
};
export const getSupplierListInCreditnote = () => {
  return async (dispatch: any) => {
    try {
      const response = await creditNotesApiServices.getSupplierList();
      if (response?.status === 200) {
        dispatch({
          type: CREDIT_NOTES_REDUX_CONSTANTS.GET_SUPPLIER_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const generateCreditAction = (
  data: Record<string, any>,
  history: any
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('generateCreditNoteLoaderAction');
      const response = await creditNotesApiServices.generateCredit(
        data,
        data?.invoiceId
      );
      if (response.status === 201) {
        dispatch({
          type: CREDIT_NOTES_REDUX_CONSTANTS.GENERATE_CREDIT,
          data: data,
        });
        successNotification(response?.data?.message);
        history.push(ROUTE_CONSTANTS_VARIABLE.CREDIT_NOTE);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('generateCreditNoteLoaderAction');
    }
  };
};

export const saveCreditNoteAction = (
  data: Record<string, any>,
  history: any
) => {
  return async (dispatch: any) => {
    try {
      const response = await creditNotesApiServices.saveCreditNoteDetails(
        data,
        data?._id
      );
      if (response.status === 200) {
        history.push(ROUTE_CONSTANTS_VARIABLE.CREDIT_NOTE);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const resetCreditNoteData = () => {
  return (dispatch: any) => {
    dispatch({
      type: CREDIT_NOTES_REDUX_CONSTANTS.RESET_CREDIT_NOTE_DATA,
    });
  };
};

export const getCreditNoteListAction = (
  params: Record<string, string | number>
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('creditNotesListLoaderAction');
      const response = await creditNotesApiServices.CreditNotesList(params);
      if (response.status === 200) {
        dispatch({
          type: CREDIT_NOTES_REDUX_CONSTANTS.CREDIT_NOTES_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('creditNotesListLoaderAction');
    }
  };
};

export const getInvoiceDropDownList = () => {
  return async (dispatch: any) => {
    try {
      const response = await creditNotesApiServices.InvoiceDropDownList();
      if (response?.status === 200) {
        dispatch({
          type: CREDIT_NOTES_REDUX_CONSTANTS.INVOICE_DROPDOWN_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getCreditNoteDetails = (creditId: string) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('getCreditNoteDetailsLoaderAction');
      const response = await creditNotesApiServices.getCreditDetails(creditId);
      if (response?.status === 200) {
        dispatch({
          type: CREDIT_NOTES_REDUX_CONSTANTS.GET_CREDIT_NOTE_DETAILS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getCreditNoteDetailsLoaderAction');
    }
  };
};
export const editCreditNoteAction = (creditId: string) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('getCreditNoteDetailsLoaderAction');
      const response = await creditNotesApiServices.editCreditNoteDetails(
        creditId
      );
      if (response.status === 200) {
        dispatch({
          type: CREDIT_NOTES_REDUX_CONSTANTS.GET_UPLOADED_CREDIT_NOTE_DETAILS,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('getCreditNoteDetailsLoaderAction');
    }
  };
};

export const changeCreditNoteStatus = (
  data: Record<string, string>,
  creditId: string,
  history: any
) => {
  return async () => {
    try {
      const response = await creditNotesApiServices.changeCreditStatus(
        data,
        creditId
      );
      if (response.status === 200) {
        successNotification(response?.data?.message);
        history.push(ROUTE_CONSTANTS_VARIABLE.CREDIT_NOTE);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const cancelCreditNote = (
  data: Record<string, string | number | []>,
  history: any
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('cancelCreditNoteLoaderAction');
      const response = await creditNotesApiServices.cancelCreditNote(data);

      if (response?.status === 200) {
        history.goBack();
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('cancelCreditNoteLoaderAction');
    }
  };
};

export const deleteCreditNotes = (ids: string, cb: () => void) => {
  return async (dispatch: any) => {
    try {
      const params = {
        creditNoteIds: ids,
      };
      const response = await creditNotesApiServices.deleteCreditNote(params);
      if (response?.status === 200) {
        successNotification(
          response?.data?.message || 'Record deleted successfully'
        );
        if (cb) {
          cb();
        }
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportCreditNoteListExcelSheet = (
  params: Record<string, string>
) => {
  return async () => {
    try {
      const response =
        await creditNotesApiServices.downloadCreditNoteListExcelSheet(params);
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportCreditNoteListPdf = (params: Record<string, any>) => {
  return async () => {
    try {
      const response = await creditNotesApiServices.downloadCreditNoteListPdf(
        params
      );
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getUploadedCreditNoteResponse = (data: Record<string, any>) => {
  return (dispatch: any) => {
    dispatch({
      type: CREDIT_NOTES_REDUX_CONSTANTS.GET_UPLOADED_CREDIT_NOTE_DETAILS,
      data,
    });
  };
};

export const modifyUploadedCreditNoteAction = (
  data: Record<string, string>
) => {
  return async () => {
    try {
      const response = await creditNotesApiServices.modifyUploadedCreditNote(
        data
      );
      if (response.status === 200) {
        window.open(response.data.data.url, '_blank');
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getModifiedUploadedCreditNoteAction = (
  data: Record<string, string>
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest(
        'getUploadedCreditNoteModifiedDataLoaderAction'
      );
      const response =
        await creditNotesApiServices.getModyfiedUploadedCreditNoteData(data);
      if (response.status === 200) {
        successNotification(response.data.data.message);
        dispatch({
          type: CREDIT_NOTES_REDUX_CONSTANTS.GET_UPLOADED_CREDIT_NOTE_DETAILS,
          data: { ...response.data.data },
        });
        return response.data.data;
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail(
        'getUploadedCreditNoteModifiedDataLoaderAction'
      );
    }
  };
};
