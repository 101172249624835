import {
  Chart as ChartJS,
  ChartArea,
  ChartData,
  registerables,
} from 'chart.js';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Bar, Line } from 'react-chartjs-2';
import Button from '../../../components/button/Button';
import { color_palette } from '../../../constants/colorPalette';
import { ReportsRefType } from './Reports';
import { useReactToPrint } from 'react-to-print';
import { useMediaQuery } from '@mui/material';
import companyLogo from '../../../assets/images/company-logo.svg';
import {
  getCostSalesDetailsAction,
  getSupplierCostSalesChartAction,
} from '../../../store/actions/reportActions/reportAction';
import { useDispatch, useSelector } from 'react-redux';
import { audConverter } from '../../../constants/regexConstants';
import {
  resetfilterOnModuleChange,
  saveAppliedFilters,
} from '../../../store/actions/listFiltersAction';
import { graphValueHelper } from '../../../helpers/graphValueHelper';

const CostSalesReportsChart: React.ForwardRefRenderFunction<
  ReportsRefType,
  {}
> = (props, ref) => {
  ChartJS.register(...registerables);
  const chartRef = useRef<ChartJS>(null);
  const isMobile = useMediaQuery('(max-width: 620px)');
  const tableRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  });
  const [category, setCategory] = useState('all');
  const dispatch = useDispatch();

  const { supplierCostSaleChart, supplierCostSaleDetails } = useSelector(
    ({ reportReducer }: Record<string, any>) => reportReducer ?? {}
  );

  const { costSaleChart } = useSelector(
    ({ listFilterReducer }: Record<string, any>) => listFilterReducer ?? {}
  );

  const onClickPrint = useReactToPrint({
    content: () => {
      const PrintElem = document.createElement('div');
      const header = `<div class='print-header'><img src="${companyLogo}" alt="foodygent" /><span>Foodygent</span></div>`;
      PrintElem.innerHTML = header;
      const bodyTitle = `<div class='print-body-title'>Cost Sales Chart</div>`;
      PrintElem.innerHTML += bodyTitle;
      const cards: Node | undefined = cardRef.current?.cloneNode(true);
      cards && PrintElem.appendChild(cards);
      const table = tableRef.current?.cloneNode(true);
      table && PrintElem.appendChild(table);
      return PrintElem;
    },
    pageStyle: `@page { size: auto; margin: 0mm; } 
    @media print { 
      body { -webkit-print-color-adjust: exact; padding: 0 40px !important; } 
    }`,
    removeAfterPrint: !isMobile,
  });

  const filters = {
    duration: costSaleChart?.duration,
    startDate: costSaleChart?.startDate,
    endDate: costSaleChart?.endDate,
  };

  function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
    const colorStart = '#fff';
    const colorEnd = '#F21D86';

    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(1, colorEnd);

    return gradient;
  }
  const totalArrayData = supplierCostSaleChart?.costToSalesChart?.map(
    (e: any) => e.total
  );
  const labelArrayData = supplierCostSaleChart?.costToSalesChart?.map(
    (e: any) => e.label
  );

  const data = {
    labels: labelArrayData,
    datasets: [
      {
        label: 'COST TO SALES',
        data: totalArrayData,
        fill: true,
        borderColor: color_palette.primary,
        pointRadius: 5,
      },
    ],
  };

  const options: any = {
    elements: {
      line: {
        tension: 0.3,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      filler: {
        propagate: true,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          // Include a dollar sign in the ticks
          callback: (value: number) => graphValueHelper(value),
        },
      },
      x: {
        grid: {
          display: false,
          offset: true,
        },
      },
    },
  };
  const onChangeCategory = (category: string) => {
    const params = {
      ...filters,
      category: category === 'All' ? undefined : category,
    };
    dispatch(getSupplierCostSalesChartAction(params));
    dispatch(getCostSalesDetailsAction(params));
    setCategory(category.toLowerCase());
  };

  const handleKeyDown = (e: any) => {
    if (e.ctrlKey && e.which === 80) {
      e.preventDefault();
      onClickPrint();
      return false;
    }
  };

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor:
          costSaleChart?.chart === 'Bar Chart'
            ? color_palette.primary
            : createGradient(chart.ctx, chart.chartArea),
        borderRadius: (context: any) => (context.chart.width > 640 ? 5 : 1),
      })),
    };

    setChartData(chartData);
  }, [supplierCostSaleChart]);

  useImperativeHandle(
    ref,
    () => {
      return {
        onPrintCostSalesChart: onClickPrint,
      };
    },
    [onClickPrint]
  );

  useEffect(() => {
    dispatch(getCostSalesDetailsAction(filters));
    setCategory(costSaleChart?.category?.toLowerCase() ?? 'all');
  }, [costSaleChart]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    dispatch(resetfilterOnModuleChange('costSaleChart'));
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <div className="category-filter-container">
        <Button
          color="primary"
          className="outlined-primary-button"
          variant={category === 'all' ? 'contained' : 'outlined'}
          onClick={() => onChangeCategory('All')}
        >
          All
        </Button>
        <Button
          color="primary"
          className="outlined-primary-button"
          variant={category === 'food' ? 'contained' : 'outlined'}
          onClick={() => onChangeCategory('Food')}
        >
          Food
        </Button>
        <Button
          color="primary"
          className="outlined-primary-button"
          variant={category === 'beverages' ? 'contained' : 'outlined'}
          onClick={() => onChangeCategory('Beverages')}
        >
          Beverages
        </Button>
        <Button
          color="primary"
          className="outlined-primary-button"
          variant={category === 'expenses' ? 'contained' : 'outlined'}
          onClick={() => onChangeCategory('Expenses')}
        >
          Expenses
        </Button>
        {/* <Button
          color="primary"
          // className="outlined-primary-button"
          variant={category === 'wages' ? 'contained' : 'outlined' }
          onClick={() => onChangeCategory('Wages')}
          disabled
        >
          Wages
        </Button> */}
      </div>

      <div
        className="white-block-row reports-white-block-row purchase-block-row cost-sales-row"
        ref={cardRef}
      >
        <div className="category-white-block-row">
          <div className="white-block">
            <div>Cost to Sales</div>
            <div className="cost-to-sales">
              {audConverter(supplierCostSaleDetails?.costToSales ?? 0)}
            </div>
          </div>
          <div className="white-block">
            <div>Other Expenses</div>
            <div>{supplierCostSaleDetails?.otherExpenses}%</div>
          </div>
        </div>
        <div className="category-white-block-row">
          <div className="white-block">
            <div>Food</div>
            <div>{supplierCostSaleDetails?.foodPercentage}%</div>
          </div>
          <div className="white-block">
            <div>Drinks</div>
            <div>{supplierCostSaleDetails?.beveragesPercentage}%</div>
          </div>
        </div>
        <div className="white-block">
          <div>{audConverter(supplierCostSaleDetails?.totalSales ?? 0)}</div>
          <div>Total Sales</div>
        </div>
      </div>

      <div className="white-block">
        <div className="reports-sub-heading">COST TO SALES</div>
        {costSaleChart?.chart === 'Bar Chart' ? (
          <Bar
            // @ts-ignore */
            ref={chartRef}
            // @ts-ignore */
            data={chartData}
            options={options}
          />
        ) : (
          <Line
            // @ts-ignore */
            ref={chartRef}
            data={chartData}
            // @ts-ignore */
            options={options}
          />
        )}
        {/* for printing table start here*/}
        <div className="print-table" ref={tableRef}>
          {supplierCostSaleChart?.costToSalesChart?.length > 0 && (
            <>
              <h2>COST TO SALE</h2>
              <div className="print-header-table print-header-charts">
                <span>Period</span>
                <span className="center-field">Total Cost to Sales</span>
              </div>
              <div className="print-table-body">
                {supplierCostSaleChart?.costToSalesChart?.map(
                  (data: Record<string, any>) => (
                    <div className="print-table-row print-body-charts">
                      <span>{data.label}</span>
                      <span className="text-center">{data.total}</span>
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </div>
        {/* for printing table end here*/}
      </div>
    </>
  );
};

export default forwardRef(CostSalesReportsChart);
