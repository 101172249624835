export const upArrowIcon = (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.30069 0.803652L0.800108 7.15644C0.614548 7.33779 0.512695 7.57948 0.512695 7.8372C0.512695 8.0952 0.614694 8.33676 0.800108 8.5181L1.39044 9.09489C1.57571 9.27609 1.82317 9.37591 2.08702 9.37591C2.35073 9.37591 2.60653 9.27609 2.7918 9.09489L6.5924 5.38876L6.5924 17.1633C6.5924 17.6942 7.01767 18.1136 7.56103 18.1136H8.39561C8.93897 18.1136 9.40711 17.6942 9.40711 17.1633V5.34671L13.2289 9.09474C13.4145 9.27595 13.6552 9.37577 13.9191 9.37577C14.1826 9.37577 14.4269 9.27595 14.6123 9.09474L15.2007 8.51796C15.3863 8.33661 15.4874 8.09506 15.4874 7.83706C15.4874 7.57934 15.385 7.33764 15.1994 7.1563L8.69897 0.803509C8.51282 0.621735 8.26434 0.521767 8.0002 0.522482C7.73517 0.52191 7.48654 0.621735 7.30069 0.803652Z"
      fill="url(#paint0_linear_5002_19149)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5002_19149"
        x1="13.6662"
        y1="19.1167"
        x2="11.6472"
        y2="-1.49406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#34DBC2" />
        <stop offset="1" stopColor="#60EA91" />
      </linearGradient>
    </defs>
  </svg>
);
