export const REPORTS_REDUX_CONSTANT = {
  GET_SUPPLIER_LIST_DROPDOWN: 'GET_SUPPLIER_LIST_DROPDOWN',
  GET_CATEGORY_DROPDOWN: 'GET_CATEGORY_DROPDOWN',
  GET_SUPPLIER_PURCHASE_CHART: 'GET_SUPPLIER_PURCHASE_CHART',
  GET_PURCHASE_FOOD_AND_BEVERAGES_DETAIL: 'GET_FOOD_AND_BEVERAGES_DETAIL',
  GET_SUPPLIER_SALES_CHART: 'GET_SUPPLIER_SALES_CHART',
  GET_SALES_FOOD_AND_BEVERAGES_DETAIL: 'GET_SALES_FOOD_AND_BEVERAGES_DETAIL',
  GET_SUPPLIER_COST_SALE_CHART: 'GET_SUPPLIER_COST_SALE_CHART',
  GET_COST_SALE_DETAILS: 'GET_COST_SALE_DETAILS',
};
