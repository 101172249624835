export const downGraphIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="14"
    viewBox="0 0 62 14"
    fill="none"
  >
    <path
      d="M1 9.95393C5.5 4.0902 8.17022 0.146439 10.5 1.15837C15 3.11292 16.6552 12.1201 20 8.48801C29 -1.28488 28.8654 14.0957 33.5 12.3972C37.5 10.9312 37 -0.307593 41 4.57884C46.6309 11.4576 43.9172 9.20199 52 5.55613C58.5 2.62423 59.5 6.04478 61 6.53342"
      stroke="#FF6161"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
