import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { ROUTE_CONSTANTS } from './RouteConstants';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import { NonAuthenticatedRoute } from './NonAuthenticatedRoute';
import { AUTH_REDUX_CONSTANTS } from '../store/reduxConstants/authReduxConstants';
import { getAuthTokenFromLocalStorage } from '../helpers/localstoragehelper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Layout from '../components/layout/Layout';
import { urlManagementHelper } from '../helpers/urlManagementHelper';
import { initialReducerProps } from '../store/reducers/authReducer';
import { userProfileDetail } from '../store/actions/ProfileAction';

declare var FoodygentAndroid: any;

const Routes = () => {
  const dispatch = useDispatch();

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const { accessModuleList } = useSelector(
    ({ authReducer }: Record<string, initialReducerProps>) => authReducer ?? {}
  );

  const routes = urlManagementHelper(accessModuleList, ROUTE_CONSTANTS);

  const [loginWithBiometrics, setLoginWithBiometrics] =
    useState<boolean>(false);

  const [biometricsPass, setBiometricsPass] = useState<boolean>(false);

  if ('FoodygentAndroid' in window && FoodygentAndroid.isBiometricAvailable()) {
    FoodygentAndroid.onAuthDialogCallback = function (message: string) {
      setBiometricsPass(message == 'success');
    };
  }

  useEffect(() => {
    setLoginWithBiometrics(
      !!profileDetail?.loginWithBiometrics &&
        'FoodygentAndroid' in window &&
        FoodygentAndroid.isBiometricAvailable()
    );
  }, [profileDetail]);

  useEffect(() => {
    if (loginWithBiometrics) {
      FoodygentAndroid.encrypt(
        '',
        'Login with biometrics',
        "Let's get you logged in",
        'Negative text'
      );
    }
  }, [loginWithBiometrics]);

  useEffect(() => {
    const cb = () => {
      const isToken = getAuthTokenFromLocalStorage();
      if (!isToken) {
        dispatch({
          type: AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS,
          status: false,
        });
      } else {
        dispatch(userProfileDetail());
      }
    };

    window.addEventListener('storage', cb);

    return () => {
      window.removeEventListener('storage', cb);
    };
  }, []);

  return (
    <Router>
      <Layout>
        <Switch>
          {routes.map(
            ({ path, component, authenticated, isEscapeRedirect }, index) => {
              const isAuthenticated = loginWithBiometrics
                ? biometricsPass && authenticated
                : authenticated;

              const Component: React.ComponentType<any> = isAuthenticated
                ? AuthenticatedRoute
                : NonAuthenticatedRoute;

              return (
                <Component
                  key={`${path}-${index}`}
                  exact
                  path={path}
                  component={component}
                  isEscapeRedirect={isEscapeRedirect}
                />
              );
            }
          )}
          <Redirect to={'/'} />
        </Switch>
      </Layout>
    </Router>
  );
};
export default Routes;
