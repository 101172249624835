import { DASHBOARD_REDUX_CONSTANT } from '../reduxConstants/dashboardConstant';

interface dashboardProps {
  pendingInvoicesList: Record<string, string | number>;
  purchaseFoodAndBeveragesChart: Record<string, any> | null;
  purchaseTopTenOrders: Record<string, any> | null;
  purchaseTopTenSupplierList: Record<string, any> | null;
  purchasePriceChangeDetails: Record<string, any> | null;
  saleFoodAndBeverageDetail: Record<string, any> | null;
  saleFoodAndBeverageChart: Record<string, any> | null;
  saleSpendPerHead: Record<string, any> | null;
  dailySale: Record<string, any> | null;
}
const initialDashboardReducer: dashboardProps = {
  pendingInvoicesList: {},
  purchaseFoodAndBeveragesChart: null,
  purchaseTopTenOrders: null,
  purchaseTopTenSupplierList: { purchase: null, suppliers: null },
  purchasePriceChangeDetails: null,
  saleFoodAndBeverageDetail: null,
  saleFoodAndBeverageChart: null,
  saleSpendPerHead: null,
  dailySale: null,
};

export const dashboardReducer = (
  state = initialDashboardReducer,
  action: { type: string; data: Record<string, any>; status: boolean }
) => {
  switch (action?.type) {
    case DASHBOARD_REDUX_CONSTANT.GET_PENDING_INVOICES:
      return {
        ...state,
        pendingInvoicesList: action.data,
      };

    case DASHBOARD_REDUX_CONSTANT.GET_FOOD_AND_BEVERAGES_CHART:
      const foodData: number[] = [];
      const beverageData: number[] = [];
      const label: string[] = [];

      action.data.weekWiseData.forEach(
        (item: { foodSales: number; bevSales: number; label: string }) => {
          foodData.push(item.foodSales);
          beverageData.push(item.bevSales);
          label.push(item.label);
        }
      );
      return {
        ...state,
        purchaseFoodAndBeveragesChart: { foodData, beverageData, label },
      };

    case DASHBOARD_REDUX_CONSTANT.GET_TOP_TEN_ORDERS:
      return {
        ...state,
        purchaseTopTenOrders: action.data,
      };

    case DASHBOARD_REDUX_CONSTANT.GET_TOP_TEN_SUPPLIERS:
      const purchase: number[] = [];
      const suppliers: string[] = [];
      action.data.forEach(
        (item: { totalPurchase: number; supplierName: string }) => {
          purchase.push(item.totalPurchase).toFixed(2);
          suppliers.push(`$${item.totalPurchase} ${item.supplierName}`);
        }
      );
      return {
        ...state,
        purchaseTopTenSupplierList: { purchase, suppliers },
      };

    case DASHBOARD_REDUX_CONSTANT.GET_PRICE_CHANGES_DETAILS:
      return {
        ...state,
        purchasePriceChangeDetails: action.data,
      };
    case DASHBOARD_REDUX_CONSTANT.GET_SALE_FOOD_AND_BEVERAGE_CHART:
      return {
        ...state,
        saleFoodAndBeverageChart: action.data,
      };
    case DASHBOARD_REDUX_CONSTANT.GET_SALE_FOOD_AND_BEVERAGE_DETAIL:
      return {
        ...state,
        saleFoodAndBeverageDetail: action.data,
      };
    case DASHBOARD_REDUX_CONSTANT.GET_SALE_SPEND_PER_HEAD_DETAIL:
      const spendPerHeadTotal: number[] = [];
      const spendPerHeadLabel: string[] = [];

      action.data.salePerHead.forEach(
        (item: { total: number; label: string }) => {
          spendPerHeadTotal.push(item.total).toFixed(2);
          spendPerHeadLabel.push(item.label);
        }
      );
      return {
        ...state,
        saleSpendPerHead: { spendPerHeadTotal, spendPerHeadLabel },
      };
    case DASHBOARD_REDUX_CONSTANT.GET_DAILY_SALE_DETAIL:
      const dailySaleFood = Array(7).fill(0);
      const dailySaleBeverages = Array(7).fill(0);
      const dailySaleLabel = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

      action.data.forEach(
        (data: {
          beveragesFigure: number;
          foodFigure: number;
          label: string;
        }) => {
          if (dailySaleLabel.includes(data.label)) {
            const index = dailySaleLabel.indexOf(data.label);
            (dailySaleFood[index] = data.foodFigure),
              (dailySaleBeverages[index] = data.beveragesFigure);
          }
        }
      );

      return {
        ...state,
        dailySale: { dailySaleFood, dailySaleBeverages, dailySaleLabel },
      };
    default:
      return state;
  }
};
