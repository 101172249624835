import { LOGIN_REDUX_CONSTANTS } from './../reduxConstants/loginReduxConstants';
import { ORDER_REDUX_CONSTANTS } from './../reduxConstants/orderReduxConstants';
import { ProductType } from './supplierReducer/productReducer';

interface OrderType {
  deliveryDate: string | null;
  deliveryTime: string | null;
  supplierId: string | null;
  products: Record<string, any>[];
}
interface OrderReducerProps {
  order: OrderType;
  orderList: Record<string, any>;
  signature: string;
  allProducts: Record<string, any>;
  pantryList: Record<string, string>;
  supplierList: Record<string, any>[] | null;
}

const initialOrderReducer: any = {
  orderList: {},
  order: {
    deliveryDate: null,
    deliveryTime: null,
    supplierId: null,
    products: [],
  },
  allProducts: {},
  pantryList: {},
  signature: '',
  supplierList: null,
};
export const orderReducer = (
  state = initialOrderReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case ORDER_REDUX_CONSTANTS.GET_ORDER_LIST:
      return {
        ...state,
        orderList: action.data,
      };

    case ORDER_REDUX_CONSTANTS.GET_SUPPLIER_LIST_FORORDER:
      return {
        ...state,
        supplierList: action.data,
      };

    case ORDER_REDUX_CONSTANTS.CREATE_ORDER_ON_CHANGE_VALUE:
      const { name, value } = action.data;
      let finalData = value;

      if (name === 'products' && value?.length) {
        finalData = value.map((product: Record<string, string | number>) => {
          return !product?.quantity ? { ...product, quantity: 1 } : product;
        });
      }
      return {
        ...state,
        order: {
          ...state.order,
          [name]: finalData,
        },
      };

    case ORDER_REDUX_CONSTANTS.RESET_ORDER_DETAILS:
      return {
        ...state,
        order: initialOrderReducer.order,
        allProducts: {},
        pantryList: {},
      };

    case ORDER_REDUX_CONSTANTS.ON_CHANGE_PRODUCT_QUANTITY:
      const { action: increamentAction, id } = action.data;

      const updatedValue =
        state.order.products?.length &&
        state.order.products.map((product: ProductType) => {
          if (product._id === id) {
            let qty = product?.quantity ?? 1;

            const quantityChanger: Record<string, number> = {
              add: qty + 1,
              remove: qty > 1 ? qty - 1 : qty,
            };

            return {
              ...product,
              quantity: quantityChanger[increamentAction],
            };
          } else return product;
        });

      return {
        ...state,
        order: {
          ...state.order,
          ['products']: updatedValue,
        },
      };

    case ORDER_REDUX_CONSTANTS.DELETE_PRODUCT_FOR_DRAFT_ORDER:
      const products = state.order.products;
      const updatedProductList =
        products &&
        products.filter(
          (product: ProductType) => product._id !== action.data.productId
        );
      return {
        ...state,
        order: {
          ...state.order,
          products: updatedProductList,
        },
      };

    case ORDER_REDUX_CONSTANTS.GET_ORDER_DETAILS_BY_ID:
      return {
        ...state,
        order: {
          ...action.data,
          deliveryDate: action.data?.deliveryDate ?? null,
          deliveryTime: action.data?.deliveryTime ?? null,
        },
      };

    case ORDER_REDUX_CONSTANTS.UPLOAD_SIGNATURE:
      return {
        ...state,
        signature: action.data,
      };

    case ORDER_REDUX_CONSTANTS.GET_ALL_PRODUCTS:
      const allProductData = action.data?.docs?.map(
        (product: Record<string, string>) => {
          const foundProduct = state.order?.products?.find(
            (products: Record<string, string>) => product.name === products.name
          );
          if (foundProduct) product['selectedProduct'] = foundProduct;
          return product;
        }
      );

      return {
        ...state,
        allProducts: { ...action.data, docs: allProductData },
      };

    case ORDER_REDUX_CONSTANTS.ON_CHANGE_UNIT:
      const { selectedProduct, keyName } = action.data;
      const selectedProducts = state[keyName]?.docs?.map(
        (product: Record<string, string>) => {
          return product.name === selectedProduct.name
            ? { ...product, ['selectedProduct']: selectedProduct }
            : { ...product };
        }
      );
      const orderProducts = state.order?.products?.map(
        (product: Record<string, string>) => {
          return product.name === selectedProduct.name
            ? { ...selectedProduct, quantity: selectedProduct.quantity ?? 1 }
            : product;
        }
      );
      return {
        ...state,
        order: { ...state.order, products: orderProducts },
        [keyName]: { ...state[keyName], docs: selectedProducts },
      };

    case ORDER_REDUX_CONSTANTS.CREATE_ORDER_QUANTITY_CHANGE:
      const { action: quanityAction, productName, listName } = action.data;
      const updatedProduct =
        state[listName]?.docs?.length &&
        state[listName]?.docs?.map((product: Record<string, any>) => {
          if (product.name === productName) {
            let qty = product?.selectedProduct.quantity ?? 1;

            const quantityChanger: Record<string, number> = {
              add: qty + 1,
              remove: qty > 1 ? qty - 1 : qty,
            };

            return {
              ...product,
              selectedProduct: {
                ...product.selectedProduct,
                quantity: quantityChanger[quanityAction],
              },
            };
          } else return product;
        });

      return {
        ...state,
        [listName]: { ...state.allProducts, docs: updatedProduct },
      };

    case ORDER_REDUX_CONSTANTS.GET_SELECTED_PRODUCT:
      const newArray = state.order?.products ?? [];
      const index = newArray?.findIndex(
        (e: Record<string, string>) => e._id === action.data._id
      );
      let selectedAllProduct = [];
      let selectedPantryListProduct = [];
      if (index >= 0) {
        newArray.splice(index, 1);
        selectedAllProduct = state.allProducts.docs.map(
          (product: Record<string, string | Record<string, string>>) => {
            if (action.data.name === product.name) {
              delete product.selectedProduct;
            }
            return product;
          }
        );
        selectedPantryListProduct = state.pantryList.docs.map(
          (product: Record<string, string | Record<string, string>>) => {
            if (action.data.name === product.name) {
              delete product.selectedProduct;
            }
            return product;
          }
        );
      } else {
        newArray?.push(
          action.data.quantity
            ? { ...action.data }
            : { ...action.data, quantity: 1 }
        );
        selectedAllProduct = state.allProducts.docs.map(
          (product: Record<string, string | Record<string, string>>) => {
            if (action.data.name === product.name) {
              product.selectedProduct = action.data;
            }
            return product;
          }
        );
        selectedPantryListProduct = state.pantryList.docs.map(
          (product: Record<string, string | Record<string, string>>) => {
            if (action.data.name === product.name) {
              product.selectedProduct = action.data;
            }
            return product;
          }
        );
      }

      return {
        ...state,
        order: { ...state.order, products: newArray },
        allProducts: { ...state.allProducts, docs: selectedAllProduct },
        pantryList: { ...state.pantryList, docs: selectedPantryListProduct },
      };

    case ORDER_REDUX_CONSTANTS.GET_PANTRY_LIST:
      const data = action.data.docs.reduce(
        (acc: Record<string, string>[], product: Record<string, string>) => {
          const foundModule = state.allProducts.docs?.find(
            (pantryProduct: Record<string, string>) =>
              product.name === pantryProduct.name
          );
          if (foundModule)
            acc.push({
              ...product,
              selectedProduct: foundModule.selectedProduct,
            });
          else acc.push(product);
          return acc;
        },
        []
      );
      return { ...state, pantryList: { ...state.pantryList, docs: data } };

    case ORDER_REDUX_CONSTANTS.DELETE_PRODUCT:
      const allProducts = state?.allProducts?.docs.map(
        (product: Record<string, any>) => {
          product.name === action.data ? delete product.selectedProduct : '';
          return product;
        }
      );
      const pantryList = state?.allProducts?.docs.map(
        (product: Record<string, any>) => {
          product.name === action.data ? delete product.selectedProduct : '';
          return product;
        }
      );
      return {
        ...state,
        allProducts: { ...state.allProducts, docs: allProducts },
        pantryList: { ...state.pantryList, docs: pantryList },
      };

    case ORDER_REDUX_CONSTANTS.EDIT_PRODUCT_NOTE:
      const { productId, productNote } = action.data;
      const product = state?.order?.products?.map(
        (product: Record<string, string>) => {
          return product._id === productId
            ? { ...product, ['productNote']: productNote }
            : { ...product };
        }
      );

      return { ...state, order: { ...state.order, products: product } };

    case ORDER_REDUX_CONSTANTS.RESET_ALL_PRODUCTS:
      return { ...state, allProducts: {}, pantryList: {} };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialOrderReducer,
      };

    default:
      return state;
  }
};
