import Button from '../../../components/button/Button';
import companyLogo from '../../../assets/images/company-logo.svg';
import myjob from '../../../assets/images/myjob.png';
import xero from '../../../assets/images/xero.png';
import fresho from '../../../assets/images/fresho.png';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  chooseOrganizationForAccountingSoftware,
  removeAccountingSoftwareAction,
  skipAccountingSoftwareIntegration,
  softwareintegration,
  validateSoftwareIntegration,
} from '../../../store/actions/accountingSoftwareActions';
import ButtonLoader from '../../../components/buttonLoader/ButtonLoader';

const AccountSoftware = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const { userId } = useParams<Record<string, string>>();
  const [selectedSoftware, setSelectedSoftware] = useState('');
  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<
    Record<string, string>
  >({});
  const [openSkipModal, setOpenSkipModal] = useState(false);

  const { organizationList, accountingSoftwareData } = useSelector(
    ({ accountingSoftwareReducer }: Record<string, any>) =>
      accountingSoftwareReducer ?? {}
  );

  const {
    getAccountingSoftwareValidationLoaderAction,
    chooseOrganizationForAccountingSoftwareLoaderAction,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? true
  );

  const onConnect = (software: string) => {
    if (selectedSoftware === software) {
      const success = dispatch(removeAccountingSoftwareAction(userId));
      if (success) {
        setSelectedSoftware('');
      }
    } else {
      setSelectedSoftware(software);
      const data = {
        userId,
        softwareName: software,
      };
      dispatch(softwareintegration(data, history));
    }
  };

  const onClickApply = async () => {
    if (!chooseOrganizationForAccountingSoftwareLoaderAction) {
      const data = {
        userId,
        ...selectedOrganization,
        softwareName: selectedSoftware,
        accountingSoftwareId: accountingSoftwareData.accountingSoftwareId,
      };

      const success = await dispatch(
        chooseOrganizationForAccountingSoftware(data, history)
      );
      if (success) {
        setOpenOrganizationModal(false);
      }
    }
  };

  const organizationSelectionButton: ButtonType[] = [
    {
      title: 'Reset',
      onClick: () => {
        setSelectedOrganization({});
      },
      color: 'secondary',
    },
    {
      title: 'Apply',
      onClick: () => {
        onClickApply();
      },
      color: 'primary',
      loader: chooseOrganizationForAccountingSoftwareLoaderAction,
    },
  ];

  const onProceed = () => {
    const data = {
      userId,
      softwareName: selectedSoftware,
      accountingSoftwareId: accountingSoftwareData.accountingSoftwareId,
    };
    const reset = () => {
      setSelectedSoftware('');
    };
    const success = dispatch(validateSoftwareIntegration(data, history, reset));
    if (success) {
      setOpenOrganizationModal(true);
    }
  };

  const onCancel = () => {
    setOpenSkipModal(true);
  };

  const skipModalButton: ButtonType[] = [
    {
      title: 'Cancel',
      onClick: () => {
        setOpenSkipModal(false);
      },
      color: 'secondary',
    },
    {
      title: 'Yes',
      onClick: () =>
        dispatch(skipAccountingSoftwareIntegration(userId, history)),
      color: 'primary',
    },
  ];

  const handleClose = () => {
    setOpenSkipModal(false);
  };

  return (
    <div className="plans-container payment-container">
      <div className="auth-company-logo plan-header-container">
        <img src={companyLogo} alt="companyLogo" />
        <div className="plan-header">
          <div>Accounting Software</div>
        </div>
      </div>

      <div className="payment-details view-page-container account-page-container">
        <div className="payment-failed">
          <div className="view-details-white-container accounting-detail-block">
            <div className="software-image">
              <img src={myjob} alt="myjob" />
            </div>
            <div>
              <Button
                color="primary"
                variant={selectedSoftware === 'Myob' ? 'contained' : 'outlined'}
                onClick={() => onConnect('Myob')}
              >
                {selectedSoftware === 'Myob' ? 'Remove' : 'Connect'}
              </Button>
            </div>
          </div>
          <div className="view-details-white-container accounting-detail-block">
            <div className="software-image">
              <img src={xero} alt="xero" />
            </div>
            <div>
              <Button
                color="primary"
                variant={selectedSoftware === 'Xero' ? 'contained' : 'outlined'}
                onClick={() => onConnect('Xero')}
              >
                {selectedSoftware === 'Xero' ? 'Remove' : 'Connect'}
              </Button>
            </div>
          </div>
          {/* <div className="view-details-white-container accounting-detail-block">
            <div className="software-image">
              <img src={fresho} alt="fresho" />
            </div>
            <div>
              <Button color="primary" variant="contained" onClick={() => {}}>
                Connect
              </Button>
            </div>
          </div> */}
          <div className="form-button-row">
            <Button variant="contained" color="secondary" onClick={onCancel}>
              Skip
            </Button>
            {selectedSoftware.length > 0 && (
              <Button
                variant="contained"
                loader={getAccountingSoftwareValidationLoaderAction}
                color="primary"
                onClick={onProceed}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </div>

      {!getAccountingSoftwareValidationLoaderAction &&
        organizationList?.length > 0 &&
        openOrganizationModal && (
          <CustomModal
            header="Select Organization"
            className="confirmation-modal organization-modal"
            bodyClassName="credential-modal-body"
            buttons={organizationSelectionButton}
            hideModal={() => {
              setOpenOrganizationModal(false);
            }}
          >
            {organizationList?.map((organization: Record<string, string>) => {
              return (
                <div className="organization-container">
                  <span>{organization?.name}</span>
                  <Button
                    color="primary"
                    variant={
                      selectedOrganization.id === organization?.id
                        ? 'outlined'
                        : 'contained'
                    }
                    onClick={() => {
                      setSelectedOrganization(organization);
                    }}
                  >
                    {selectedOrganization.id === organization?.id
                      ? 'Remove'
                      : 'Connect'}
                  </Button>
                </div>
              );
            })}
          </CustomModal>
        )}

      {openSkipModal && (
        <CustomModal
          header="Skip Accounting Software"
          buttons={skipModalButton}
          hideModal={handleClose}
          className="delete-modal"
          headerClassName="delete-modal-header"
          bodyClassName="delete-modal-body"
          footerClassName="delete-modal-footer"
        >
          <span className="delete-modal-content">
            Are you sure you want to Skip?
          </span>
        </CustomModal>
      )}
    </div>
  );
};
export default AccountSoftware;
