import { displayErrors } from './../../helpers/errorNotifyHelper';
import { Dispatch } from 'react';
import { successNotification } from '../../components/notifyHelper';
import { profileDetailType } from '../../pages/profile-module/editProfile/EditProfile';
import ProfileApiServices from '../../services/apiService/profileApiServices/ProfileApiServices';
import { PROFILE_REDUX_CONSTANTS } from '../reduxConstants/profileReduxConstant';
import { AUTH_REDUX_CONSTANTS } from '../reduxConstants/authReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';

import { getAuthTokenFromLocalStorage } from '../../../src/helpers/localstoragehelper';

type ChangePasswordType = {
  currentPassword: string;
  newPassword: string;
};
export const userProfileDetail = () => {
  return async (dispatch: any) => {
    try {
      const response = await ProfileApiServices.getProfileDetail();

      if (response?.status === 200) {
        dispatch({
          type: PROFILE_REDUX_CONSTANTS.GET_USER_PROFILE_DETAIL,
          data: response?.data?.data,
        });
        dispatch({
          type: AUTH_REDUX_CONSTANTS.CHANGE_AUTH_STATUS,
          data: response?.data?.data?.moduleAccess,
          status: true,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const userProfileDetailImapConfig = async () => {
  try {
    // Attempt to fetch user profile details from an API using an asynchronous call
    const response = await ProfileApiServices.getProfileDetail();

    // Check if the HTTP response status is 200, indicating a successful request
    if (response?.status === 200) {
      // Check if the "imapConfig" attribute exists in the API response data
      if (response?.data?.data && 'imapConfig' in response.data.data) {
        // If it exists, extract and return the "imapConfig" attribute
        return response.data.data.imapConfig;
      } else {
        // If it doesn't exist, you can return a default value or handle it as needed
        return null; // or throw an error, log a message, etc.
      }
    } else {
      // If the status is not 200, you might want to throw an error or handle it differently
      throw new Error('API request did not return a 200 status.');
    }
  } catch (e) {
    // If any errors occur during the API request, re-throw the error
    throw e;
  }
};

export const onChangeProfileDetail = (
  name: string,
  value: string | boolean | Record<string, string | undefined> | null
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: PROFILE_REDUX_CONSTANTS.ON_CHANGE_PROFILE_DETAIL,
      data: {
        name,
        value,
      },
    });
  };
};

export const updateProfileDetail = (
  updateProfileDetail: Record<string, any>
) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('updateProfileLoader');
      const response = await ProfileApiServices.updateProfileDetail(
        updateProfileDetail
      );

      if (response?.status === 200) {
        dispatch({
          type: PROFILE_REDUX_CONSTANTS.GET_USER_PROFILE_DETAIL,
          data: updateProfileDetail,
        });
        successNotification(response?.data?.message);
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('updateProfileLoader');
    }
  };
};

export const uploadProfilePicture = (data: FormData, config: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await ProfileApiServices.uploadProfilePicture(
        data,
        config
      );
      if (response?.status === 200) {
        dispatch({
          type: PROFILE_REDUX_CONSTANTS.PROFILE_PICTURE,
          data: response.data.data,
        });

        successNotification(
          response?.data?.message || 'Profile picture updated successfully'
        );
      }
      return true;
    } catch (e) {
      displayErrors(e);
      return false;
    }
  };
};

export const changePassword = (data: ChangePasswordType) => {
  return async () => {
    try {
      const response = await ProfileApiServices.changePassword(data);
      if (response?.status === 200) {
        successNotification(
          response?.data?.message ||
            'Your password have been changed successfully'
        );
      }
    } catch (e) {
      displayErrors(e);
      throw Error();
    }
  };
};

export const changeEmailFetchOption = (data: any) => {
  return async (dispatch: any) => {
    try {
      const token = getAuthTokenFromLocalStorage();
      const config = {
        headers: {
          'Content-Type': 'application/json',
          authorization: `${token}`,
        },
      };
      const response = await ProfileApiServices.changeFetchOption(data, config);
      if (response?.status === 200) {
        successNotification(
          response?.data?.message ||
            `${response.data.emailFetchOption} is selected for Invoice Upload `
        );
      }
    } catch (error) {
      displayErrors(error);
      throw Error();
    }
  };
};
