export const INVOICE_REDUX_CONSTANTS = {
  INVOICE_LIST: 'INVOICE_LIST',
  GET_INVOICE_DETAILS_BY_ID: 'GET_INVOICE_DETAILS_BY_ID',
  GET_ORDER_DROPDOWN_LIST: 'GET_ORDER_DROPDOWN_LIST',
  ON_CHANGE_UPDATE_STATUS_DATA: 'ON_CHANGE_UPDATE_STATUS_DATA',
  RESET_UPDATE_STATUS_DATA: 'RESET_UPDATE_STATUS_DATA',
  GET_SUPPLIER_DROPDOWN: 'GET_SUPPLIER_DROPDOWN',
  GET_USER_DROPDOWN: 'GET_USER_DROPDOWN',
  RESET_INVOICE_DETAILS: 'RESET_INVOICE_DETAILS',
  UPLOAD_INVOICE: 'UPLOAD_INVOICE',
  GET_SUPPLIER_DROPDOWN_LIST: 'GET_SUPPLIER_DROPDOWN_LIST',
  GET_PRODUCT_DROPDOWN_LIST: 'GET_PRODUCT_DROPDOWN_LIST',
  ON_CHANGE_INVOICE_DATA: 'ON_CHANGE_INVOICE_DATA',
  UPLOAD_CREATE_INVOICE: 'UPLOAD_CREATE_INVOICE',
  DELETE_INVOICE_PRODUCT: 'DELETE_INVOICE_PRODUCT',
  RESET_ADD_INVOICE: 'RESET_ADD_INVOICE',
  ON_CHANGE_QUANTITY: 'ON_CHANGE_QUANTITY',
  ON_CHANGE_QUANTITY_AMOUNT: 'ON_CHANGE_QUANTITY_AMOUNT',
  ON_INVOICE_INPUT_CHANGE: 'ON_INVOICE_INPUT_CHANGE',
  GET_EDIT_INVOICE_DATA: 'GET_EDIT_INVOICE_DATA',
  REMOVE_UPLOADED_INVOICE: 'REMOVE_UPLOADED_INVOICE',
  GET_MAPPER_DATA: 'GET_MAPPER_DATA',
  GET_INVENTORY_PRODUCTS: 'GET_INVENTORY_PRODUCTS',
  ON_MAPPER_DATA_CHANGE: 'ON_MAPPER_DATA_CHANGE',
};
