import react, { createRef, useEffect, useRef, useState } from 'react';
import { NUMBER_REGEX } from '../../constants/regexConstants';
import { displayErrors } from '../../helpers/errorNotifyHelper';

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACE_BAR = 32;

type OtpInputProps = {
  otpInputs: number;
  isInputNum: boolean;
  onOtpFill: (val: any) => void;
  onFocusOtpBoxClass: string;
  otpBoxWithValueClass: string;
  onEnter: any;
};

const OtpInput = (props: OtpInputProps) => {
  const {
    otpInputs,
    isInputNum,
    onOtpFill,
    onFocusOtpBoxClass,
    otpBoxWithValueClass,
    onEnter,
    ...restProps
  } = props;
  const otpBlankArray = new Array(otpInputs).fill('_');
  const inputRefs = useRef<any>(otpBlankArray.map(() => createRef()));

  const [otp, setOtp] = useState(otpBlankArray);

  const isInputValueValid = (value: string) => {
    const isTypeValid = isInputNum
      ? !isNaN(Number(value))
      : typeof value === 'string';
    return isTypeValid && value.trim().length === 1;
  };

  // Focus on next input
  const focusNextInput = (e: any) => {
    if (e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };

  // Focus on previous input
  const focusPrevInput = (e: any) => {
    if (e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  // Change OTP value at focused input
  const changeCodeAtFocus = (value: string, index?: number) => {
    setOtp(otp.map((e, i) => (i === index ? value : e)));
  };

  const handleOnChange = (event: any, index: number) => {
    const { value } = event.target;
    if (isInputValueValid(value)) {
      changeCodeAtFocus(value, index);
    }
  };

  // The content may not have changed, but some input took place hence change the focus
  const handleOnInput = (e: any, i: number) => {
    if (
      isInputValueValid(NUMBER_REGEX.test(e.target.value) && e.target.value)
    ) {
      focusNextInput(e);
    } else {
      // This is a workaround for dealing with keyCode "229 Unidentified" on Android.

      if (!isInputNum) {
        const { nativeEvent } = e;

        if (
          nativeEvent.data === null &&
          nativeEvent.inputType === 'deleteContentBackward'
        ) {
          e.preventDefault();
          changeCodeAtFocus('');
          focusPrevInput(e);
        }
      }
    }
  };

  const otpInputOnKeyDown = (
    e: react.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.keyCode === BACKSPACE || e.key === 'Backspace') {
      e.preventDefault();
      changeCodeAtFocus('', index);
      focusPrevInput(e);
    } else if (e.keyCode === DELETE || e.key === 'Delete') {
      e.preventDefault();
      changeCodeAtFocus('', index);
    } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
      e.preventDefault();
      focusPrevInput(e);
      if (index === 0) {
        inputRefs.current[otpInputs - 1].current.focus();
      }
    } else if (
      e.keyCode === RIGHT_ARROW ||
      e.key === 'ArrowRight' ||
      e.key === 'TAB' ||
      e.key === 'Tab'
    ) {
      e.preventDefault();
      focusNextInput(e);
      if (index === otpInputs - 1) {
        inputRefs.current[0].current.focus();
      }
    } else if (e.key === 'Enter') {
      onEnter();
    } else if (
      e.keyCode === SPACE_BAR ||
      e.key === ' ' ||
      e.key === 'Spacebar' ||
      e.key === 'Space' ||
      !NUMBER_REGEX.test(e.key)
    ) {
      e.preventDefault();
    }
  };

  const onFocus = (
    event: react.FocusEvent<HTMLInputElement, Element>,
    index: number
  ) => {
    event.target.select();
    inputRefs?.current?.[index].current?.classList?.add(onFocusOtpBoxClass);
    otpBlankArray.map(
      (_, i) =>
        index !== i &&
        inputRefs?.current?.[i]?.current?.classList?.remove(onFocusOtpBoxClass)
    );
  };

  useEffect(() => {
    inputRefs?.current?.[0]?.current?.classList?.add(onFocusOtpBoxClass);
    otpBlankArray.map(
      (_, i) =>
        i !== 0 &&
        inputRefs?.current?.[i].current?.classList?.remove(onFocusOtpBoxClass)
    );
  }, []);

  const onOtpPaste = async (e: react.ClipboardEvent<HTMLInputElement>) => {
    const copiedText = e.clipboardData.getData('text/plain');

    if (!isNaN(Number(copiedText)) && !copiedText.includes('e' || '.')) {
      const separatedText = copiedText.replaceAll(' ', '').split('');
      if (separatedText.length === otpInputs) {
        setOtp(separatedText);
      } else {
        displayErrors('Clipboard text is not valid OTP');
      }
    }
  };

  useEffect(() => {
    if (otp.join('').replaceAll(' ', '').length === otpInputs) onOtpFill(otp);
  }, [otp]);

  const otpInputBoxes = otp.map((d, i) => (
    <input
      className={`otp-box ${
        d?.toString().trim().length && otpBoxWithValueClass
      } 
      ${
        document?.activeElement?.nodeName === `otp${i}`
          ? onFocusOtpBoxClass
          : ''
      }`}
      maxLength={1}
      ref={inputRefs.current[i]}
      name={`otp${i}`}
      key={i}
      type="number"
      autoFocus={i === 0}
      onPaste={(e) => onOtpPaste(e)}
      value={d}
      onChange={(e) => handleOnChange(e, i)}
      onInput={(e) => handleOnInput(e, i)}
      onKeyDown={(e) => otpInputOnKeyDown(e, i)}
      onFocus={(e) => onFocus(e, i)}
    />
  ));

  return (
    <form className="otp-container" {...restProps}>
      {otpInputBoxes}
    </form>
  );
};

OtpInput.defaultProps = {
  isInputNum: true,
  onOtpFill: () => {},
  onFocusOtpBoxClass: '',
  otpBoxWithValueClass: '',
};

export default OtpInput;
