import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import {
  getQueryListAction,
  onChageQueryAction,
  raisequeryAction,
} from '../../store/actions/supportActions';
import { useEffect, useState } from 'react';
import { SUPPORT_REDUX_CONSTANTS } from '../../store/reduxConstants/supportReduxConstants';
import { resetfilterOnModuleChange } from '../../store/actions/listFiltersAction';
import { enterKeyHelper } from '../../helpers/enterKeyHelper';

const Support = () => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<string | false>(false);

  const { query, queryList } = useSelector(
    ({ supportReducer }: Record<string, any>) => supportReducer ?? {}
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(onChageQueryAction(name, value));
  };

  const onSaveQuery = async () => {
    dispatch({
      type: SUPPORT_REDUX_CONSTANTS.RESET_QUERY,
    });
    let value: string = query.supportQuery;
    if (
      !value?.toString()?.trim()?.length ||
      value === undefined ||
      value === null
    ) {
      dispatch(onChageQueryAction('error', 'Please enter query'));
    } else {
      const data = {
        ['supportQuery']: query.supportQuery,
      };
      await dispatch(raisequeryAction(data));
      await dispatch(getQueryListAction());
    }
  };

  const onCancelClick = () => {
    dispatch({ type: SUPPORT_REDUX_CONSTANTS.RESET_QUERY });
  };

  useEffect(() => {
    dispatch(getQueryListAction());
    dispatch(resetfilterOnModuleChange('support'));
  }, []);

  return (
    <div className="view-page-container support-form">
      <div className="form-title-row">
        <span className="page-title">Support</span>
      </div>
      <div className="main-details-container">
        <div className="input-field-margin-bottom">
          <div className="query-list-title"> Query </div>
          <FormControl className="input-field">
            <Input
              variant="filled"
              placeholder="Enter Query"
              className={`input-field ${
                query?.error ? 'input-with-error' : ''
              }`}
              name="supportQuery"
              value={query?.supportQuery}
              type="text"
              multiline
              rows={3}
              helperText={query?.error}
              onChange={onChangeInput}
              onKeyUp={(event) => enterKeyHelper(event, () => onSaveQuery())}
            />
          </FormControl>
          <div className="form-button-row">
            <Button
              variant="contained"
              color="secondary"
              onClick={onCancelClick}
            >
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onSaveQuery}>
              Save
            </Button>
          </div>
        </div>
        {queryList?.length > 0 && (
          <>
            <div className="query-list-title">List of Query</div>
            <div className="query-list">
              {queryList?.map((query: any) => (
                <Accordion
                  expanded={expanded === query._id && query?.comment.length > 0}
                  onChange={handleChange(query._id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id={query._id}
                  >
                    <Typography>{query.query}</Typography>
                  </AccordionSummary>

                  {query?.comment?.map((answer: string) => (
                    <AccordionDetails>
                      <Typography>{`~ ${answer}`}</Typography>{' '}
                    </AccordionDetails>
                  ))}
                </Accordion>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Support;
