import { SUPPLIERS_URLS } from '../../../constants/urlConstants';
import { ProductType } from '../../../store/reducers/supplierReducer/productReducer';
import ApiService from '../../apiService/apiService';

type ProductIdsType = {
  productIds: string;
};
const ProductApiServices = {
  productList: (params: Record<string, any>) =>
    ApiService.getData(SUPPLIERS_URLS.PRODUCTS_URLS.GET_PRODUCTS_LIST, {
      params,
    }),
  supplierList: () =>
    ApiService.getData(SUPPLIERS_URLS.PRODUCTS_URLS.GET_DROPDOWN_SUPPLIER_LIST),
  addProduct: (data: ProductType) =>
    ApiService.postData(SUPPLIERS_URLS.PRODUCTS_URLS.ADD_PRODUCT, data),
  deleteProduct: (params: ProductIdsType) =>
    ApiService.deleteData(`${SUPPLIERS_URLS.PRODUCTS_URLS.DELETE_PRODUCTS}`, {
      params,
    }),
  getProductDetailsById: (productId: string) =>
    ApiService.getData(
      `${SUPPLIERS_URLS.PRODUCTS_URLS.GET_PRODUCT_DETAILS_BY_ID}${productId}`
    ),
  updateProductDetailsById: (
    data: Record<string, string | number | undefined | null>,
    productId: string
  ) =>
    ApiService.putData(
      `${SUPPLIERS_URLS.PRODUCTS_URLS.UPDATE_PRODUCT_DETAILS_BY_ID}${productId}`,
      data
    ),
  downloadProductExcelSheet: (params: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: SUPPLIERS_URLS.PRODUCTS_URLS.EXPORT_TO_EXCEL,
      responseType: 'blob',
      params: params,
    }),
  downloadProductPdf: (params: Record<string, string>) =>
    ApiService.request({
      method: 'GET',
      url: SUPPLIERS_URLS.PRODUCTS_URLS.EXPORT_TO_PDF,
      responseType: 'blob',
      params: {
        ...params,
        userDate: new Date(),
      },
    }),
};
export default ProductApiServices;
