import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import Button from '../../../../components/button/Button';
import BackButton from '../../../../components/backButton/BackButton';
import companyLogo from '../../../../assets/images/company-logo.svg';
import { useHistory } from 'react-router-dom';
import paymentFailed from '../../../../assets/images/payment-failed.png';

const PaymentFailed = () => {
  const history = useHistory();
  return (
    <div className="plans-container payment-container">
      <div className="auth-company-logo plan-header-container">
        <img src={companyLogo} alt="Foodygent" />
        <div className="plan-header">
          <div>
            <BackButton
              className="plan-back-button"
              onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN)}
            />
            Payment Failed
          </div>
        </div>
      </div>

      <div className="payment-details">
        <div className="payment-failed">
          <div className="payment-failed-image">
            <img src={paymentFailed} alt="Payment Failed" />
          </div>
          <div className="payment-failed-text">
            Your transaction has failed due to some technical error. Please try
            again.
          </div>
        </div>
        <div className="plan-confirm-button-container">
          <Button
            className="auth-button plan-confirm-button"
            variant="contained"
            color="primary"
            onClick={''}
          >
            Try Again
          </Button>
        </div>
      </div>
    </div>
  );
};
export default PaymentFailed;
