import { downloadAll } from '../../helpers/downloadHelper';
import { displayErrors } from '../../helpers/errorNotifyHelper';
import productInventoryApiServices from '../../services/pageServices/productInventoryApiServices';
import { PRODUCT_INVENTORY_REDUX_CONSTANTS } from '../reduxConstants/productInventoryReduxConstants';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from './generalLoaderAction';

export const getProductInventoryListaction = (params: Record<string, any>) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('productInventoryListLoaderAction');
      const response = await productInventoryApiServices.productInventoryList(
        params
      );
      if (response.status === 200) {
        dispatch({
          type: PRODUCT_INVENTORY_REDUX_CONSTANTS.GET_PRODUCT_INVENTORY_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('productInventoryListLoaderAction');
    }
  };
};

export const getInventoryFilterSupplier = () => {
  return async (dispatch: any) => {
    try {
      const response = await productInventoryApiServices.filterSupplierList();
      if (response.status === 200) {
        dispatch({
          type: PRODUCT_INVENTORY_REDUX_CONSTANTS.GET_FILTER_SUPPLIER_DROPDOWN,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getInventoryFilterProduct = () => {
  return async (dispatch: any) => {
    try {
      const response = await productInventoryApiServices.filterProductList();
      if (response.status === 200) {
        dispatch({
          type: PRODUCT_INVENTORY_REDUX_CONSTANTS.GET_FILTER_PRODUCT_DROPDOWN,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getProductInventoryDetailsById = (productId: string) => {
  return async (dispatch: any) => {
    try {
      startGeneralLoaderOnRequest('productInventoryDetailsLoaderAction');
      const response =
        await productInventoryApiServices.getProductInventoryDetailsById(
          productId
        );
      if (response.status === 200) {
        dispatch({
          type: PRODUCT_INVENTORY_REDUX_CONSTANTS.GET_PRODUCT_INVENTORY_DETAILS_BY_ID,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    } finally {
      stopGeneralLoaderOnSuccessOrFail('productInventoryDetailsLoaderAction');
    }
  };
};

export const exportProductInventoryExcelsheet = (
  params: Record<string, string>
) => {
  return async () => {
    try {
      const response =
        await productInventoryApiServices.productInventoryExportToExcel(params);
      if (response.status === 200) {
        if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const exportProductInventoryPDF = (params: Record<string, string>) => {
  return async () => {
    try {
      const response =
        await productInventoryApiServices.productInventoryExportToPdf(params);
      if (response.status === 200) {
        if (response.headers['content-type'] === 'application/pdf')
          downloadAll(response);
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
