import { australiaStates } from './../australiaStates';
import { FormField } from '../../constants/interfaces';
import {
  ABN_REGEX,
  ALPHA_NUMERIC_REGEX,
  EMAIL_ADDRESS_REGEX,
  MOBILE_NUMBER_REGEX,
} from '../../constants/regexConstants';

export const supplierConstants: FormField[] = [
  {
    name: 'companyName',
    label: 'Company Name',
    placeholder: 'Company Name',
    suffix: 'search',
  },
  {
    name: 'abn',
    label: 'ABN / Company Number',
    placeholder: 'ABN / Company Number',
    regexCheck: ABN_REGEX,
    suffix: 'search',
  },

  {
    name: 'ordersEmail',
    label: 'Orders Email',
    placeholder: 'Orders Email',
    regexCheck: EMAIL_ADDRESS_REGEX,
  },
  {
    name: 'name',
    label: 'Rep Name',
    placeholder: 'Rep Name',
  },
  {
    name: 'tradingName',
    label: 'Trading Name',
    placeholder: 'Trading Name',
  },
  {
    name: 'category',
    label: 'Category/SubCategory',
    placeholder: 'Category',
    type: 'multiLevelDropdown',
    isMulti: true,
  },
  {
    name: 'repNumber',
    label: 'Rep Number',
    placeholder: 'Rep Number',
  },
  {
    name: 'contactEmail',
    label: 'Account Contact Email',
    placeholder: 'Account Contact Email',
  },
  {
    name: 'contactNumber',
    label: 'Account Contact Number',
    placeholder: 'Account Contact Number',
    regexCheck: MOBILE_NUMBER_REGEX,
  },
  {
    name: 'address',
    label: 'Address',
    placeholder: 'Address',
    isFull: true,
  },
  {
    name: 'city',
    label: 'City',
    placeholder: 'City',
  },
  {
    name: 'postalCode',
    label: 'Zip Code / Postal Code',
    placeholder: 'Zip Code / Postal Code',
    regexCheck: ALPHA_NUMERIC_REGEX,
  },
  {
    name: 'country',
    label: 'Country',
    placeholder: 'Country',
    type: 'autocomplete',
  },
  {
    name: 'state',
    label: 'State',
    type: 'state',
    placeholder: 'State',
    options: australiaStates,
  },

  {
    name: 'paymentTerms',
    label: 'Payment Terms',
    placeholder: 'Payment Terms',
    type: 'autocomplete',
  },
  {
    name: 'paymentMethod',
    label: 'Payment Method',
    placeholder: 'Payment Method',
    type: 'autocomplete',
  },
  {
    name: 'otherPaymentTerm',
    label: 'Other Payment Term',
    placeholder: 'Other Payment Term',
    isFull: true,
  },
  {
    name: 'instructions',
    label: 'Delivery Instructions',
    placeholder: 'Delivery Instructions',
    type: 'textarea',
    isFull: true,
    isMulti: true,
  },
];

export const supplierRequireFields = [
  'abn',
  'companyName',
  'ordersEmail',
  'name',
  'category',
  'contactNumber',
  'instructions',
];
