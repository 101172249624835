export const ADMIN_PLAN_REDUX_CONSTANTS = {
  ADMIN_PLAN_LIST: 'ADMIN_PLAN_LIST',
  ON_CHANGE_PLAN_DATA: 'ON_CHANGE_PLAN_DATA',
  GET_SELECTED_PLAN_DETAILS: 'GET_SELECTED_PLAN_DETAILS',
  UPDATE_SELECTED_PLAN_DETAILS: 'UPDATE_SELECTED_PLAN_DETAILS',
  RESET_PLAN_DATA: 'RESET_PLAN_DATA',
  ON_CHANGE_PLAN_TYPE: 'ON_CHANGE_PLAN_TYPE',
  ON_CHANGE_FACILITIES: 'ON_CHANGE_FACILITIES',
  ADMIN_PLAN_FACILITIES_LIST: 'ADMIN_PLAN_FACILITIES_LIST',
};
