import { LOGIN_REDUX_CONSTANTS } from '../reduxConstants/loginReduxConstants';
import { SUPPORT_REDUX_CONSTANTS } from '../reduxConstants/supportReduxConstants';

interface SupportProps {
  queryList: Record<string, any>;
  query: Record<string, string>;
}
const initialSupportReducer: SupportProps = {
  queryList: {},
  query: {
    supportQuery: '',
    error: '',
  },
};

export const supportReducer = (
  state = initialSupportReducer,
  action: { type: string; data: Record<string, any>; status?: boolean }
) => {
  switch (action?.type) {
    case SUPPORT_REDUX_CONSTANTS.ON_CHANGE_QUERY:
      const { name, query } = action.data;
      return {
        ...state,
        query: {
          ...state.query,
          [name]: query,
        },
      };

    case SUPPORT_REDUX_CONSTANTS.GET_QUERY:
      return {
        ...state,
        queryList: action.data,
      };

    case SUPPORT_REDUX_CONSTANTS.RESET_QUERY:
      return { ...state, query: { ...initialSupportReducer.query } };

    case LOGIN_REDUX_CONSTANTS.LOGOUT_USER_ACTION:
      return {
        ...initialSupportReducer,
      };

    default:
      return { ...state };
  }
};
