export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const HEADER_URLS = {
  LOGOUT_URL: `${BASE_URL}auth/logout`,
};

export const AUTH_URLS = {
  ON_REGISTER_URL: `${BASE_URL}auth/sign-up`,
  LOGIN_URL: `${BASE_URL}auth/login`,
  FORGOT_PASSWORD_URL: `${BASE_URL}auth/forgot-password`,
  RESET_PASSWORD_URL: `${BASE_URL}auth/reset-password`,
  SET_PASSWORD_URL: `${BASE_URL}auth/set-password`,
  GET_QR_CODE: `${BASE_URL}2FA/generate-qrcode`,
  VERIFY_OTP: `${BASE_URL}2FA/verify-OTP`,
  GET_DETAIL_BY_NAME: `${BASE_URL}abn-lookup/name`,
  GET_DETAIL_BY_ABN: `${BASE_URL}abn-lookup`,
  RESET_TWO_FACTOR: `${BASE_URL}auth/reset-2FA`,

  PLAN: {
    GET_PLAN_URL: `${BASE_URL}plan/auth/list-of-plans`,
    CONFIRM_PLAN: `${BASE_URL}plan/auth/update-details/`,
  },

  CARD: {
    GET_CARD_LIST: `${BASE_URL}card/auth/list-of-cards`,
    ADD_CARD: `${BASE_URL}card/auth/add-card`,
    SELECT_CARD: `${BASE_URL}card/auth/update-details/`,
  },

  PAYMENT: {
    GET_PAYMENT_DETAILS: `${BASE_URL}plan/auth/current-plan-details/`,
    MAKE_PAYMENT: `${BASE_URL}payment/auth/accept/`,
  },

  ACCOUNTING_SOFTWARE: {
    SOFTWARE_INTEGRATION: `${BASE_URL}software-integration/auth/connect`,
    VALIDATE_INTEGRATION: `${BASE_URL}software-integration/auth/update-details`,
    CHOOSE_ORGANIZATION: `${BASE_URL}software-integration/auth/choose-organization`,
    SKIP_ACCOUNTING_SOFTWARE: `${BASE_URL}software-integration/auth/skip`,
    REMOVE_ACCOUNTING_SOFTWARE: `${BASE_URL}software-integration/auth/remove`,
  },
};

export const PROFILE_URLS = {
  GET_PROFILE_DETAIL: `${BASE_URL}user/profile`,
  UPLOAD_PROFILE_IMAGE: `${BASE_URL}user/profile/image`,
  CHANGE_PASSWORD_URL: `${BASE_URL}auth/change-password`,
  CHANGE_EMAILFETCHOPTION: `${BASE_URL}user/email-fetch-option`,
};

export const NOTIFICATION_URLS = {
  CONNECT_FIREBASE: `${BASE_URL}notification/connectFirebase`,
  GET_ALL_NOTIFICATION: `${BASE_URL}notification/allNotifications`,
  CLEAR_NOTIFICATION: `${BASE_URL}notification/clearNotification`,
  DISCONNECT_FIREBASE: `${BASE_URL}notification/disconnectFirebase`,
};

export const ACCOUNT_SETTINGS_URLS = {
  USERS_URLS: {
    GET_USERS_LIST: `${BASE_URL}user/`,
    ADD_USER: `${BASE_URL}user/`,
    EDIT_USER: `${BASE_URL}user/`,
    IMPORT_USERS: `${BASE_URL}user/import-user`,
    GET_USER_BY_ID: `${BASE_URL}user/`,
    DELETE_USERS: `${BASE_URL}user/`,
    RESEND_EMAIL: `${BASE_URL}user/resendInviteEmail/`,
    GET_MODULE_LIST: `${BASE_URL}user/modules`,
    UPDATE_STATUS: `${BASE_URL}user/status/`,
  },
  SOFTWARE_INTEGRATION_URLS: {
    SOFTWARE_INTEGRATION: `${BASE_URL}software-integration/connect`,
    GET_CURRENT_SOFTWARE: `${BASE_URL}software-integration/current-details`,
    VALIDATE_INTEGRATION: `${BASE_URL}software-integration/update-details`,
    CHOOSE_ORGANIZATION: `${BASE_URL}software-integration/choose-organization`,
    RESET_INTEGRATION: `${BASE_URL}software-integration/restore`,
    REMOVE_INTEGRATION: `${BASE_URL}software-integration/remove`,
  },
  BILLING_OVERVIEW_URLS: {
    GET_BILLING_OVERVIEW_DETAILS: `${BASE_URL}billing-overview`,
    CANCEL_CURRENT_PLAN: `${BASE_URL}billing-overview/cancel-current-plan`,
    GET_PLAN_LIST: `${BASE_URL}billing-overview/list-of-plans`,
    GET_CARD_LIST: `${BASE_URL}billing-overview/list-of-cards`,
    ADD_CARD_DETAIL: `${BASE_URL}billing-overview/add-card`,
    GET_PAYMENT_DETAIL_UPGRADE_PLAN: `${BASE_URL}billing-overview`,
    GET_PAYMENT_STATUS_UPGRADE_PLAN: `${BASE_URL}billing-overview/accept-payment`,
    UPDATE_ACCOUNT_INFO: `${BASE_URL}billing-overview/update-account-info`,
    DELETE_CARD: `${BASE_URL}billing-overview/card/`,
    CHANGE_DEFAULT_CARD: `${BASE_URL}billing-overview/change-default-card/`,
  },
  BILLING_HISTORY_URLS: {
    GET_BILLING_HISTORY_LIST: `${BASE_URL}billing-history/`,
    EXPORT_TO_EXCEL: `${BASE_URL}billing-history/list/excel-download`,
    EXPORT_TO_PDF: `${BASE_URL}billing-history/list/pdf-download`,
  },
};

export const SUPPLIERS_URLS = {
  SUPPLIERS_URLS: {
    GET_SUPPLIERS_LIST: `${BASE_URL}supplier`,
    DELETE_SUPPLIERS: `${BASE_URL}supplier`,
    IMPORT_SUPPLIERS: `${BASE_URL}supplier/import-supplier`,
    GET_PAYMENT_METHOD: `${BASE_URL}supplier/payment-options`,
    ADD_SUPPLIER: `${BASE_URL}supplier`,
    GET_SUPPLIER_DETAILS_BY_ID: `${BASE_URL}supplier/`,
    EDIT_SUPPLIER_URL: `${BASE_URL}supplier/`,
    EXPORT_TO_EXCEL: `${BASE_URL}supplier/list/excel-download/`,
    EXPORT_TO_PDF: `${BASE_URL}supplier/list/pdf-download`,
    UPLOAD_TERMS_AND_CONDITIONS: `${BASE_URL}supplier/upload/tc`,
    VIEW_EXPORT: `${BASE_URL}supplier/`,
  },
  PRODUCTS_URLS: {
    GET_PRODUCTS_LIST: `${BASE_URL}product`,
    ADD_PRODUCT: `${BASE_URL}product`,
    GET_PRODUCT_DETAILS_BY_ID: `${BASE_URL}product/`,
    GET_DROPDOWN_SUPPLIER_LIST: `${BASE_URL}product/supplier/list`,
    UPDATE_PRODUCT_DETAILS_BY_ID: `${BASE_URL}product/`,
    DELETE_PRODUCTS: `${BASE_URL}product`,
    IMPORT_PRODUCTS: `${BASE_URL}product/import-product/`,
    EXPORT_TO_EXCEL: `${BASE_URL}product/list/excel-download`,
    EXPORT_TO_PDF: `${BASE_URL}product/list/pdf-download`,
  },
};

export const COMMON_URLS = {
  GET_CATEGORY: `${BASE_URL}category/list`,
  GET_SUPPLIER_DROPDOWN_DATA: `${BASE_URL}supplier/list`,
  GET_USER_ROLE: `${BASE_URL}user/roles`,
  GET_UNITS: `${BASE_URL}unit/list`,
  UPDATE_UNITS: `${BASE_URL}unit/`,
  UPDATE_CATEGORY_LIST: `${BASE_URL}category/`,
  DOWNLOAD_SAMPLE_CSV: `${BASE_URL}static/download-sample-csv`,
  GET_PRODUCT_DROPDOWN_DATA: `${BASE_URL}product/list`,
  CATEGORY_SUNCATEGORY_DROPDOWN: `${BASE_URL}category/list/categories-and-subcategories`,
  PRODUCT_DROPDOWN_LIST: `${BASE_URL}product/distinct/list`,
  CURRENCY_CONVERSION: `${BASE_URL}convert-currency`,
  FETCH_ATTACHMENTS: `${BASE_URL}emails/fetch_attachments`,
};

export const PRODUCT_INVENTORY_URLS = {
  GET_PRODUCT_INVENROTY_LIST: `${BASE_URL}product-inventory`,
  GET_INVENTORY_FILTER_SUPPLIER_LIST: `${BASE_URL}product-inventory/supplier/list`,
  GET_INVENTORY_PRODUCT_LIST: `${BASE_URL}product-inventory/product/list`,
  GET_PRODUCT_INVENTORY_DETAIL_BY_ID: `${BASE_URL}product-inventory/`,
  PRODUCT_INVENTORY_EXPORT_TO_PDF: `${BASE_URL}product-inventory/list/pdf-download`,
  PRODUCT_INVENTORY_EXPORT_TO_EXCEL: `${BASE_URL}product-inventory/list/excel-download`,
};

export const ORDERS_URLS = {
  ORDER_LIST: `${BASE_URL}order/`,
  CREATE_ORDER: `${BASE_URL}order/`,
  DELETE_ORDERS: `${BASE_URL}order/`,
  VIEW_ORDER_DETAILS: `${BASE_URL}order/`,
  GET_SUPPLIER_LIST_FORORDER: `${BASE_URL}order/supplier/list`,
  CONFIRM_AND_SEND_ORDER: `${BASE_URL}order/confirm/`,
  RECEIVED_ORDER: `${BASE_URL}order/invoice/`,
  UPLOAD_SIGNATURE: `${BASE_URL}order/add-signature/`,
  GET_ALL_PRODUCT: `${BASE_URL}order/productList`,
  GET_PANTRY_LIST: `${BASE_URL}order/pantry-list`,
  ADD_ON_DISABLE: `${BASE_URL}order/add-on-disable/`,
  EXPORT_TO_EXCEL: `${BASE_URL}order/`,
  EXPORT_TO_PDF: `${BASE_URL}order/`,
  UPDATE_ORDER: `${BASE_URL}order/`,
  ORDER_REORDER: `${BASE_URL}order/`,
  ORDER_RECEIVE: `${BASE_URL}order/`,
};

export const RECIPE_URLS = {
  RECIPE_LIST: `${BASE_URL}recipe/`,
  ADD_RECIPE: `${BASE_URL}recipe`,
  UPLOAD_RECIPE_PHOTO: `${BASE_URL}recipe/image`,
  GET_RECIPE_DETAILS_BY_ID: `${BASE_URL}recipe/`,
  UPDATE_RECIPE_DETAILS_BY_ID: `${BASE_URL}recipe/`,
  DELETE_RECIPE: `${BASE_URL}recipe/`,
  EXPORT_TO_EXCEL: `${BASE_URL}recipe/list/excel-download`,
  EXPORT_TO_PDF: `${BASE_URL}recipe/list/pdf-download`,
  VIEW_EXPORT: `${BASE_URL}recipe/`,
  GET_RECIPE_CATEGORY_LIST: `${BASE_URL}recipe/list/subcategories`,
  UPDATE_RECIPE_CATEGORY: `${BASE_URL}recipe/recipe-categories`,
  RECIPE_CATEGORY_LIST_DROPDOWN: `${BASE_URL}recipe/category/list`,
  RECIPE_PRODUCT_LIST: `${BASE_URL}recipe/product/list`,
  PREVIOUSE_RECIPE_DROPDOWN: `${BASE_URL}recipe/list`,
  SAVE_AS_DRAFT: `${BASE_URL}recipe/update/status/draft`,
  UPDATE_DRAFT_RECIPE: `${BASE_URL}recipe/`,
};

export const SALES_URLS = {
  ADD_SALE: `${BASE_URL}sale/`,
  SALES_LIST: `${BASE_URL}sale/`,
  GET_SALE_DETAILS_BY_ID: `${BASE_URL}sale/`,
  UPDATE_SALE_DETAILS_BY_ID: `${BASE_URL}sale/`,
  DELETE_SALE: `${BASE_URL}sale/`,
  EXPORT_TO_EXCEL: `${BASE_URL}sale/list/excel-download`,
  EXPORT_TO_PDF: `${BASE_URL}sale/list/pdf-download`,
};

export const SUBSCRIBERS_URLS = {
  SUBSCRIBERS_LIST: `${BASE_URL}subscribers`,
  VIEW_SUBSCRIBER: `${BASE_URL}subscribers/detail`,
  SUBSCRIBER_PLAN_DROPDOWN: `${BASE_URL}subscribers/filters`,
  CANCEL_PLAN: `${BASE_URL}plan/`,
};

export const GST_URLS = {
  GST_LIST: `${BASE_URL}gst/`,
  ADD_GST: `${BASE_URL}gst/`,
  ADD_DEFAULT_GST: `${BASE_URL}gst/default`,
  DEFAULT_GST: `${BASE_URL}gst/default/`,
  DELETE_GST: `${BASE_URL}gst/`,
  GET_GST_DETAIL_BY_ID: `${BASE_URL}gst/`,
  UPDATE_GST_DETAIL_BY_ID: `${BASE_URL}gst/update/`,
};

export const INVOICE_URLS = {
  INVOICES_URLS: {
    INVOICE_LIST: `${BASE_URL}invoice/`,
    UPLOAD_INVOICE: `${BASE_URL}invoice/upload/`,
    GET_INVOICE_DETAILS_BY_ID: `${BASE_URL}invoice/`,
    GET_ORDER_LIST: `${BASE_URL}invoice/orders`,
    ACCEPT_INVOICE: `${BASE_URL}invoice/status/accept/`,
    PENDING_INVOICE: `${BASE_URL}invoice/status/pending/`,
    DELETE_INVOICES: `${BASE_URL}invoice`,
    GET_SUPPLIER_DROPDOWN: `${BASE_URL}invoice/supplier/list`,
    GET_USERS_DROPDOWN: `${BASE_URL}invoice/user/list`,
    GET_INVOICE_DETAILS_BY_ORDER_ID: `${BASE_URL}order/`,
    MODIFY_INVOICE: `${BASE_URL}invoice/`,
    GET_MODIFIED_DATA: `${BASE_URL}invoice/`,
    EXPORT_TO_EXCEL: `${BASE_URL}invoice/list/excel-download/`,
    EXPORT_TO_PDF: `${BASE_URL}invoice/list/PDF-download/`,
    MODIFY_UPLOADED_INVOICE: `${BASE_URL}invoice/modify/`,
    GET_MODIFY_UPLOADED_DATA: `${BASE_URL}invoice/modified/document`,
    SYNC_INVOICE: `${BASE_URL}invoice/`,
    SUPPLIER_DROPDOWN: `${BASE_URL}invoice/supplier/list`,
    PRODUCT_DROPDOWN: `${BASE_URL}invoice/product/list/`,
    UPLOAD_CREATE_INVOICE: `${BASE_URL}invoice/upload/file`,
    ADD_INVOICE: `${BASE_URL}invoice`,
    GET_EDIT_INVOICE_DATA: `${BASE_URL}invoice/edit/`,
    UPDATE_INVOICE: `${BASE_URL}invoice/`,
    AUTO_SYNC: `${BASE_URL}invoice/auto-sync`,
    GET_MAPPER_DATA: `${BASE_URL}invoice/mapper-details/`,
    INVENTORY_PRODUCT_DROPDOWN: `${BASE_URL}invoice/list-of-inventory`,
    ADD_CONFIG: `${BASE_URL}user/imap-config`,
  },
  CREDIT_NOTES_URLS: {
    UPLOAD_PHOTO_FOR_CREDIT: `${BASE_URL}credit-note/upload/image`,
    GENERATE_CREDIT: `${BASE_URL}credit-note/`,
    CREDIT_NOTE_LIST: `${BASE_URL}credit-note/`,
    INVOICE_DROPDOWN_LIST: `${BASE_URL}credit-note/invoice/list`,
    UPLOAD_CREDIT_NOTE: `${BASE_URL}credit-note/upload`,
    CREDIT_NOTE_DETAILS: `${BASE_URL}credit-note/`,
    EDIT_CREDIT_NOTE_DETAILS: `${BASE_URL}credit-note/edit/`,
    SAVE_CREDIT_NOTE: `${BASE_URL}credit-note/`,
    CANCEL_CREDIT_NOTE: `${BASE_URL}credit-note/cancel-credit-note`,
    DELETE_CREDIT_NOTE: `${BASE_URL}credit-note`,
    CHANGE_CREDIT_STATUS: `${BASE_URL}credit-note/status/`,
    EXPORT_TO_EXCEL: `${BASE_URL}credit-note/list/excel-download`,
    EXPORT_TO_PDF: `${BASE_URL}credit-note/list/pdf-download`,
    GET_MODIFIED_UPLOADED_CREDIT_NOTE: `${BASE_URL}credit-note/modified/document/`,
    MODIFY_UPLOADED_CREDIT_NOTE: `${BASE_URL}credit-note/modify/`,
    GET_SUPPLIER_LIST_IN_CREDITNOTE: `${BASE_URL}credit-note/supplier/list`,
  },
  EXPENSE_URL: {
    EXPENSE_LIST: `${BASE_URL}expense/`,
    UPLOAD_INVOICE: `${BASE_URL}expense/upload/`,
    UPDATE_STATUS: `${BASE_URL}expense/status/`,
    DELETE_EXPENSE: `${BASE_URL}expense/`,
    EXPORT_TO_EXCEL: `${BASE_URL}expense/list/excel-download`,
    EXPORT_TO_PDF: `${BASE_URL}expense/list/pdf-download`,
    EXPENSE_SUPPLIER_DROPDOWN: `${BASE_URL}expense/list`,
    MODIFY_EXPENSE: `${BASE_URL}expense/`,
    GET_MODIFIED_DATA: `${BASE_URL}expense/`,
    EXPENSE_MANUAL_SYNC: `${BASE_URL}expense/`,
    EXPENSE_AUTO_SYNC: `${BASE_URL}expense/auto-sync`,
    GET_MODIFIED_UPLOADED_DATA: `${BASE_URL}expense/modified/document/`,
    MODIFY_UPLOADED_EXPENSE: `${BASE_URL}expense/modify/`,
  },
  STATEMENTS_URLS: {
    STATEMENTS_LIST: `${BASE_URL}statement/`,
    UPLOAD_STATEMENT: `${BASE_URL}statement/upload/`,
    GET_DETAILS_BY_ID: `${BASE_URL}statement/`,
    UPDATE_STATUE_TO_PENDING: `${BASE_URL}statement/status/pending/`,
    UPDATE_STATUS_TO_RECONCILED: `${BASE_URL}statement/status/reconcile/`,
    DELETE_STATEMENT: `${BASE_URL}statement/`,
    MODIFY_STATEMENT: `${BASE_URL}statement/`,
    GET_MODIFIED_DATA: `${BASE_URL}statement/`,
    EXPORT_TO_EXCEL: `${BASE_URL}statement/list/excel-download`,
    EXPORT_TO_PDF: `${BASE_URL}statement/list/pdf-download`,
    SYNC_STATEMENT: `${BASE_URL}statement/`,
    AUTO_SYNC: `${BASE_URL}statement/auto-sync`,
    GET_SUPPLIER_LIST_IN_STATEMENT: `${BASE_URL}statement/supplier/list`,
    GET_MODIFIED_UPLOADED_DATA: `${BASE_URL}statement/modified/document/`,
    MODIFY_UPLOADED_EXPENSE: `${BASE_URL}statement/modify/`,
  },
  RECONCILE_URLS: {
    RECONCILE_LIST: `${BASE_URL}reconcile`,
    GET_SUPPLIER_LIST: `${BASE_URL}reconcile/supplier/list`,
    GET_STATEMENT_LIST: `${BASE_URL}reconcile/statements`,
    GET_INVOICE_ID_LIST: `${BASE_URL}reconcile/invoices`,
    UPDATE_STATUS: `${BASE_URL}reconcile/`,
    ADD_ADJUSTMENT_NOTE: `${BASE_URL}reconcile/`,
    EXPORT_TO_EXCEL: `${BASE_URL}reconcile/list/excel-download`,
    EXPORT_TO_PDF: `${BASE_URL}reconcile/list/pdf-download`,
  },
};

export const SUPPORT_URLS = {
  REAISE_QUERY: `${BASE_URL}support`,
  GET_QUERY_LIST: `${BASE_URL}support`,
};

export const ADMIN_PLAN_URLS = {
  ADD_PLAN: `${BASE_URL}plan`,
  EDIT_PLAN: `${BASE_URL}plan/`,
  VIEW_PLAN: `${BASE_URL}plan/`,
  DELETE_PLAN: `${BASE_URL}plan/`,
  GET_PLANS_LIST: `${BASE_URL}plan`,
  GET_PLANS_FACILITIES_LIST: `${BASE_URL}plan/facilities`,
  CANCEL_PLAN: `${BASE_URL}plan/`,
};

export const UPLOAD_TERMS_AND_POLICY_URL = {
  GET_TERMS_LIST: `${BASE_URL}documents/list/terms-and-conditions/`,
  GET_POLICT_LIST: `${BASE_URL}documents/list/privacy-policy/`,
  UPLOAD_ORGANIZATION_TERMS_AND_CONDITION: `${BASE_URL}documents/upload/terms-and-conditions`,
  UPLOAD_ORGANIZATION_PRIVACY_POLICY: `${BASE_URL}documents/upload/privacy-and-policy`,
  SAVE_ORGANIZATION_DOCUMENTS: `${BASE_URL}documents/saveDocuments`,
};

export const REPORT_URL = {
  RECONCILED_STATEMENTS_URL: {
    GET_RECONCILED_STATEMENTS_LIST: `${BASE_URL}reconciled-statements`,
    SUPPLIER_LIST_DROPDOWN: `${BASE_URL}reconciled-statements/supplier/list`,
    EXPORT_TO_EXCEL: `${BASE_URL}reconciled-statements/list/excel-download`,
    EXPORT_TO_PDF: `${BASE_URL}reconciled-statements/list/pdf-download`,
  },
  REPORTS_URL: {
    GET_SUPPLIER_LIST_DROPDOWN: `${BASE_URL}purchase/supplier/list`,
    CATEGORY_DROPDOWN_LIST: `${BASE_URL}purchase/category/list`,
    GET_SUPPLIER_PURCHASE_CHART: `${BASE_URL}purchase/supplier-purchases`,
    GET_PURCHASE_FOOD_AND_BEVERAGES_DETAIL: `${BASE_URL}purchase/food-and-beverages`,
    DOWNLOAD_PURCHASE_TO_EXCEL: `${BASE_URL}purchase/excel-download`,
    DOWNLOAD_PURCHASE_TO_PDF: `${BASE_URL}purchase/pdf-download`,
    GET_SUPPLIER_SALE_CHART: `${BASE_URL}sales/sales-chart`,
    GET_SALE_FOOD_AND_BEVERAGES_DETAIL: `${BASE_URL}sales/food-and-beverages`,
    DOWNLOAD_SALE_TO_EXCEL: `${BASE_URL}sales/excel-download`,
    DOWNLOAD_SALE_TO_PDF: `${BASE_URL}sales/pdf-download`,
    GET_SUPPLIER_COST_SALE_CHART: `${BASE_URL}cost-sales/cost-sales-chart`,
    GET_COST_SALE_DETAILS: `${BASE_URL}cost-sales/cost-sales-details`,
    DOWNLOAD_COST_SALE_EXCEL: `${BASE_URL}cost-sales/excel-download`,
    DOWNLOAD_COST_SALE_PDF: `${BASE_URL}cost-sales/pdf-download`,
  },
};
export const DASHBOARD_URL = {
  PURCHASE_CHART_URLS: {
    GET_PURCHASE_PENDING_INVOICE_LIST: `${BASE_URL}dashboard/purchase/pending-invoices`,
    GET_PURCHASE_FOOD_AND_BEVERAGES_DETAILS: `${BASE_URL}dashboard/purchase/food-and-beverages`,
    GET_PURCHASE_FOOD_AND_BEVERAGES_CHART: `${BASE_URL}dashboard/purchase/food-and-beverages-chart`,
    GET_PURCHASE_TOP_TEN_ORDERS: `${BASE_URL}dashboard/purchase/top-orders`,
    GET_PURCHASE_TOP_SUPPLIERS_DETAILS: `${BASE_URL}dashboard/purchase/top-suppliers`,
    GET_PURCHASE_RECENT_PRICE_CHANGE_DETAILS: `${BASE_URL}dashboard/purchase/recent-price-changes`,
  },
  SALES_CHART_URLS: {
    GET_SALE_FOOD_AND_BEVERAGES_DETAIL: `${BASE_URL}dashboard/sales/food-and-beverages`,
    GET_SALE_FOOD_AND_BEVERAES_CHART: `${BASE_URL}dashboard/sales/food-and-beverages-chart`,
    GET_SPEND_PER_HEAD_DETAIL: `${BASE_URL}dashboard/sales/spend-per-head`,
    GET_DAILY_SALE_DETAIL: `${BASE_URL}dashboard/sales/daily-sales`,
  },
};

export const ADMIN_SOFTWARE_INTEGRAION_URLS = {
  SOFTWARE_INTEGRATION: `${BASE_URL}software-integration/connect-for-super-admin`,
  GET_CURRENT_SOFTWARE: `${BASE_URL}software-integration/current-details-for-super-admin`,
  VALIDATE_INTEGRATION: `${BASE_URL}software-integration/update-details-for-super-admin`,
  CHOOSE_ORGANIZATION: `${BASE_URL}software-integration/choose-organization-for-super-admin`,
  RESET_INTEGRATION: `${BASE_URL}software-integration/restore-for-super-admin`,
  REMOVE_INTEGRATION: `${BASE_URL}software-integration/remove-for-super-admin`,
};
