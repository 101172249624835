import { useHistory, useParams } from 'react-router';
import BackButton from '../../../components/backButton/BackButton';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import Button from '../../../components/button/Button';
import { useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import { uploadSignatureAction } from '../../../store/actions/orderAction';
import { useDispatch, useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { errorNotification } from '../../../components/notifyHelper';

const ApplyForCredit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [signatureBox, setSignatureBox] = useState(false);
  const sigCanvas = useRef<SignatureCanvas>(null);
  const params: Record<string, string> = useParams();

  const { profileDetail } = useSelector(
    ({ profileReducer }: Record<string, any>) => profileReducer ?? {}
  );

  const onClickYes = () => {
    history.push(`/order/generateCreditNote/${params?.orderId}`);
  };
  const hasAccessOfCreditnote = profileDetail?.moduleAccess
    .find(
      (e: Record<string, string | boolean | string[]>) => e.name === 'document'
    )
    .subModules.includes('credit-note');

  const onClickReset = () => sigCanvas?.current?.clear();

  const onClickSubmit = () => {
    const canvas = sigCanvas.current?.getCanvas();
    if (!canvas) {
      errorNotification('Something went wrong!');
    }
    canvas?.toBlob((blob: any) => {
      const formData = new FormData();
      formData.append('order-signature', blob, `signature.png`);
      const config: Record<string, Record<string, string>> = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      if (sigCanvas.current?.isEmpty()) {
        errorNotification('Please Enter Signature');
      } else {
        dispatch(
          uploadSignatureAction(formData, config, params?.orderId, history)
        );
      }
    }, 'image/png');
  };
  useEffect(() => {
    if (!hasAccessOfCreditnote) setSignatureBox(true);
  }, []);

  return (
    <div className="form">
      <div className="form-title-row">
        <BackButton
          onClick={() => {
            history.push(ROUTE_CONSTANTS_VARIABLE.ORDER);
          }}
        />
        <span className="form-title">
          {hasAccessOfCreditnote ? 'Apply For Credit' : 'Add Signature'}
        </span>
      </div>
      <div className="form-container">
        {hasAccessOfCreditnote && (
          <>
            <div className="title">Do you want to apply for credit?</div>
            <div className="button-row">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setSignatureBox(true);
                }}
              >
                No
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  onClickYes();
                }}
              >
                Yes
              </Button>
            </div>
          </>
        )}
        {signatureBox && (
          <div>
            <div className="title">Add Signature</div>
            <div className="signature-container">
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{
                  className: 'signatureCanvas',
                }}
              ></SignaturePad>
            </div>
            <div className="button-row">
              <Button
                color="secondary"
                variant="contained"
                onClick={onClickReset}
              >
                Reset
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={onClickSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplyForCredit;
