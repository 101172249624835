export const graphIcon = (
  <svg
    width="69"
    height="16"
    viewBox="0 0 69 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 9.95393C8.5 4.0902 11.1702 0.146439 13.5 1.15837C18 3.11292 19.6552 12.1201 23 8.48801C32 -1.28488 31.8654 14.0957 36.5 12.3972C40.5 10.9312 40 -0.307593 44 4.57884C49.6309 11.4576 46.9172 9.20199 55 5.55613C61.5 2.62423 62.5 6.04478 64 6.53342"
      stroke="url(#paint0_linear_5002_19144)"
      strokeWidth="2"
      strokeLinecap="round"
    />

    <defs>
      <linearGradient
        id="paint0_linear_5002_19144"
        x1="0.578948"
        y1="11.1274"
        x2="60.3059"
        y2="-14.7903"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#34DBC2" />
        <stop offset="1" stopColor="#60EA91" />
      </linearGradient>
    </defs>
  </svg>
);
