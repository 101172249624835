import UploadFile from './uploadFile';
import Button from '../../../components/button/Button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPolicyListAction,
  getTermsListAction,
  saveTermsAndPolicyDocuments,
} from '../../../store/actions/admin/uploadTermsAndPolicyAction';
import ListRow from '../../../components/listRow/ListRow';
import Loader from '../../../components/loader/Loader';
import ButtonLoader from '../../../components/buttonLoader/ButtonLoader';
import { errorNotification } from '../../../components/notifyHelper';
import { useHistory } from 'react-router';
import { ADMIN_ROUTE_CONST } from '../../../routes/RouteConstants';
import { resetfilterOnModuleChange } from '../../../store/actions/listFiltersAction';

const UploadTermsAndPolicy = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const [open, setOpen] = useState<Record<string, any>>({});

  const {
    termsAndConditionsListLoaderAction,
    privacyPolicyListLoaderAction,
    saveDocumentLoaderAction,
  } = useSelector(
    ({ generalLoaderReducer }: Record<string, any>) =>
      generalLoaderReducer ?? {}
  );

  const { termsList, policyList } = useSelector(
    ({ uploadTermsAndPolicyReducer }: Record<string, any>) =>
      uploadTermsAndPolicyReducer ?? {}
  );

  const onClickSave = async () => {
    let newTermsList = [];
    let newPolicyList = [];
    if (termsList.docs.length === 0) {
      errorNotification(
        'Please upload at least one document for terms and conditions'
      );
    } else if (
      termsList.docs.filter(
        (term: Record<string, string | boolean>) => term.isActive === true
      ).length === 0
    ) {
      errorNotification('Please activate any one term and condition');
    } else {
      newTermsList = await termsList.docs.map(
        (term: Record<string, string>) => {
          term._id?.includes('unsaved') && delete term._id;
          return term;
        }
      );
    }

    if (policyList.docs.length === 0) {
      errorNotification(
        'Please upload at least one document for privacy policy'
      );
    } else if (
      policyList.docs.filter(
        (term: Record<string, string | boolean>) => term.isActive === true
      ).length === 0
    ) {
      errorNotification('Please activate any one privacy policy');
    } else {
      newPolicyList = await policyList.docs.map(
        (policy: Record<string, string>) => {
          policy._id?.includes('unsaved') && delete policy._id;
          return policy;
        }
      );
    }

    if (newTermsList.length > 0 && newPolicyList.length > 0) {
      const FinalData = {
        termsAndConditions: newTermsList,
        privacyAndPolicy: newPolicyList,
      };
      const isSavedSuccessfully = await dispatch(
        saveTermsAndPolicyDocuments(FinalData)
      );
      if (isSavedSuccessfully) {
        dispatch(getTermsListAction());
        dispatch(getPolicyListAction());
      }
    }
  };
  useEffect(() => {
    dispatch(getTermsListAction());
    dispatch(getPolicyListAction());
    dispatch(resetfilterOnModuleChange(''));
  }, []);

  return (
    <div className="form support-form terms-and-condition-form">
      <div className="form-title-row">
        <span className="form-title">Upload T & C, Privacy Policy</span>
      </div>
      {termsAndConditionsListLoaderAction || privacyPolicyListLoaderAction ? (
        <Loader />
      ) : (
        <div className="upload-list">
          <div>
            <div className="main-details-container">
              <div className="details-block-title">Terms and Conditions</div>
              <UploadFile id="uploadTermsAndCondition" />
              <div className="list-row-container">
                {termsList?.docs?.map(
                  (term: Record<string, string | boolean>, index: number) => (
                    <ListRow
                      isActive={term?.isActive}
                      row={term}
                      index={index}
                      openRow={open}
                      setOpenRow={setOpen}
                      isFor="termsAndCondition"
                    />
                  )
                )}
              </div>
            </div>
            <div className="main-details-container">
              <div className="details-block-title">Privacy Policy</div>
              <UploadFile id="uploadPrivacyPolicy" />

              <div className="list-row-container">
                {policyList?.docs?.map(
                  (term: Record<string, string | boolean>, index: any) => (
                    <ListRow
                      isActive={term?.isActive}
                      row={term}
                      index={index}
                      openRow={open}
                      setOpenRow={setOpen}
                      isFor="privacyPolicy"
                    />
                  )
                )}
              </div>
            </div>
          </div>
          <div className="form-button-row">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(ADMIN_ROUTE_CONST.SUBSCRIBERS);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              loader={saveDocumentLoaderAction}
              color="primary"
              onClick={onClickSave}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadTermsAndPolicy;
