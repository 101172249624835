import { INVOICE_NUMBER_REGEX } from '../../../../constants/regexConstants';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../../routes/RouteConstants';
import {
  addInvoiceAction,
  onChangeInvoiceData,
  updateInvoiceAction,
} from '../../../../store/actions/invoiceActions/invoiceActions';

export const addInvoiceValidation: any = async (
  dispatch: any,
  invoiceDetails: Record<string, any>,
  existingErrors: Record<string, any>,
  isSubmittedOnce: boolean,
  requestFrom: string,
  fieldName: string | undefined,
  action: string,
  setIsSubmittedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>,
  history: any
) => {
  let validated = true;
  let errors: { [key: string]: string | undefined } = existingErrors ?? {};
  let errorMessages: Record<string, string> = {
    supplierId: 'select supplier',
    products: 'select product ',
    invoiceNumber: 'enter invoice number',
    gstPercentage: 'enter valid gst',
    invoiceDate: 'select invoice date',
    fileURL: 'upload Invoice',
  };
  const checkValidations = (field: string, value: any) => {
    switch (field) {
      case 'supplierId':
      case 'invoiceNumber':
      case 'invoiceDate':
      case 'fileURL':
        if (
          !value?.toString()?.trim()?.length ||
          value === undefined ||
          value === null
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;

      case 'products':
        if (
          !value?.toString()?.trim()?.length ||
          value === undefined ||
          value === null
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else if (value?.toString()?.trim()?.length) {
          delete errors[field];
          const finalProducts = value.map((item: Record<string, any>) =>
            item.price == 0
              ? { ...item, isError: true }
              : { ...item, isError: false }
          );
          const error = finalProducts.filter(
            (item: Record<string, any>) => item.isError
          );
          error.length > 0 && (validated = false);

          dispatch(onChangeInvoiceData('products', finalProducts));
        }
        break;

      case 'invoiceNumber':
        if (
          !value?.toString()?.trim()?.length ||
          !INVOICE_NUMBER_REGEX.test(value?.toString().trim())
        ) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;
      case 'gstPercentage':
        if (!value?.toString()?.trim()?.length || value > 100) {
          validated = false;
          if (errors) {
            errors[field] = `Please ${errorMessages[field]}`;
          }
        } else {
          delete errors[field];
        }
        break;
    }
  };
  if (isSubmittedOnce) {
    if (requestFrom === 'onSubmit') {
      for (const key in invoiceDetails) {
        const value = invoiceDetails[key];
        checkValidations(key, value);
      }
    } else if (fieldName) {
      checkValidations(fieldName, invoiceDetails[fieldName]);
    }
    dispatch(onChangeInvoiceData('errors', errors));
  }
  if (validated && requestFrom === 'onSubmit') {
    const finalData = invoiceDetails;
    for (const key in finalData) {
      if (!finalData[key]?.toString()?.trim()?.length) {
        finalData[key] = undefined;
      } else {
        switch (key) {
          case 'supplierId':
            finalData[key] = finalData[key]._id;
            break;

          case 'gstPercentage':
            finalData[key] = parseFloat(finalData[key] ?? 10);
            break;

          case 'invoiceNumber':
          case 'fileURL':
            finalData[key] = finalData[key].toString().trim();
            break;

          default:
            finalData[key] = finalData[key];
            break;
        }
      }
    }
    delete invoiceDetails?.errors;

    if (action === 'add') {
      const isAddedSuccessFully = await dispatch(addInvoiceAction(finalData));

      if (isAddedSuccessFully) {
        setIsSubmittedSuccessfully(true);
      }
    } else {
      const updateUrl = () => history.push(ROUTE_CONSTANTS_VARIABLE.INVOICE);
      dispatch(updateInvoiceAction(invoiceDetails._id, finalData, updateUrl));
    }
  }
};
