import Input from '../../../components/input/Input';
import { useHistory, useParams } from 'react-router';
import BackButton from '../../../components/backButton/BackButton';
import { ROUTE_CONSTANTS_VARIABLE } from '../../../routes/RouteConstants';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from '../../../components/button/Button';
import { FormControl, Icon, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedSaleDetails,
  onChangeSaleData,
  resetSaleData,
} from '../../../store/actions/salesAction';
import { addSaleValidations } from './addSaleValidations';
import CustomModal, {
  ButtonType,
} from '../../../components/customModal/CustomModal';
import rightIcon from '../../../assets/images/right-icon.svg';
import { getCategories } from '../../../store/actions/commonAction';
import Loader from '../../../components/loader/Loader';
import ButtonLoader from '../../../components/buttonLoader/ButtonLoader';
import CalenderIcon from '../../../assets/images/CalenderIcon';
import { focusHelper } from '../../../helpers/focusHelper';
import _ from 'lodash';
import { enterKeyHelper } from '../../../helpers/enterKeyHelper';

const AddSale = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [categoryModal, setCategoryModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const { action, saleId } = useParams<Record<string, string>>();

  const onClickAddCategory = () => {
    setCategoryModal(true);
  };

  const { categories } = useSelector(
    ({ commonReducer }: Record<string, any>) => commonReducer ?? {}
  );

  const { sale } = useSelector(
    ({ salesReducer }: Record<string, any>) => salesReducer ?? {}
  );

  const { getCategoriesInAddSaleLoaderAction, updateSaleDetailsLoaderAction } =
    useSelector(
      ({ generalLoaderReducer }: Record<string, any>) =>
        generalLoaderReducer ?? true
    );

  const onChangeDetail = (name: string, value: any) => {
    dispatch(onChangeSaleData(name, value));
    if (isSubmitted) {
      addSaleValidations(
        dispatch,
        { ...sale, [name]: value },
        errors,
        isSubmitted,
        'onBlur',
        name,
        action,
        setIsSubmittedSuccessfully,
        history
      );
    }
  };

  const { errors } = sale || {};

  const onInputBlur = (name: string) => {
    addSaleValidations(
      dispatch,
      sale,
      errors,
      isSubmitted,
      'onBlur',
      name,
      action,
      setIsSubmittedSuccessfully,
      history
    );
  };

  const onClickAddSale = (action: string) => {
    setIsSubmitted(true);
    addSaleValidations(
      dispatch,
      sale,
      errors,
      true,
      'onSubmit',
      undefined,
      action,
      setIsSubmittedSuccessfully,
      history
    );
    if (!_.isEmpty(errors)) {
      focusHelper(Object.keys(errors)?.[0]);
    }
  };

  const okButton: ButtonType[] = [
    {
      title: 'Okay',
      onClick: () => {
        history.push(ROUTE_CONSTANTS_VARIABLE.SALE);
      },
      color: 'primary',
    },
  ];

  useEffect((): any => {
    return () => dispatch(resetSaleData());
  }, []);

  useEffect(() => {
    if (saleId) dispatch(getSelectedSaleDetails(saleId));
  }, [saleId]);

  return (
    <div className="form">
      <div className="form-title-row">
        <BackButton
          onClick={() => {
            history.push(ROUTE_CONSTANTS_VARIABLE.SALE);
          }}
        />
        <span className="form-title">{`${
          action === 'add' ? 'Add' : 'Edit'
        } Sale`}</span>
      </div>

      {getCategoriesInAddSaleLoaderAction ? (
        <Loader />
      ) : (
        <div className="form-container">
          <div className="add-sale-form">
            <div className="input-field-margin-bottom">
              <div className="form-field-name">Date</div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  className={`inputDate ${
                    sale?.errors?.[`date`] ? 'input-with-error' : ''
                  }`}
                  label=""
                  // open={open}
                  // onClose={() => setOpen(false)}
                  openTo="day"
                  showToolbar={false}
                  views={['year', 'month', 'day']}
                  value={sale?.date}
                  inputFormat="dd MMMM yyyy"
                  disableFuture
                  onChange={(e) => {
                    setOpen(true);
                    onChangeDetail('date', e);
                  }}
                  components={{ OpenPickerIcon: CalenderIcon }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position={'end'}
                        onClick={() => setOpen((e) => !e)}
                      >
                        <Icon>
                          <CalenderIcon />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params: any) => (
                    <Input
                      variant="filled"
                      onKeyUp={(event) =>
                        enterKeyHelper(event, () => onClickAddSale(action))
                      }
                      onClick={() => setOpen(true)}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Select Date',
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {sale?.errors?.date && (
                <div className="error-message">{sale.errors.date}</div>
              )}
            </div>
            <div className="title-button-container">
              <div className="sale-title">Sales</div>
            </div>
            <div className="input-field-margin-bottom">
              <div className="form-field-name">Food</div>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder={`Enter Food Sale In $`}
                  className={`input-field ${
                    sale?.errors?.[`foodSales`] ? 'input-with-error' : ''
                  }`}
                  name="FoodSales"
                  id="foodSales"
                  value={sale?.foodSales}
                  type="text"
                  helperText={sale?.errors?.[`foodSales`]}
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddSale(action))
                  }
                  onChange={(event) =>
                    onChangeDetail(`foodSales`, event.target.value)
                  }
                  onBlur={() => onInputBlur(`foodSales`)}
                />
              </FormControl>
            </div>
            <div className="input-field-margin-bottom">
              <div className="form-field-name">Beverages</div>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder={`Enter Beverages Sale In $`}
                  className={`input-field ${
                    sale?.errors?.[`beveragesSales`] ? 'input-with-error' : ''
                  }`}
                  name="beveragesSales"
                  id="beveragesSales"
                  value={sale?.[`beveragesSales`]}
                  type="text"
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddSale(action))
                  }
                  helperText={sale?.errors?.[`beveragesSales`]}
                  onChange={(event) =>
                    onChangeDetail(`beveragesSales`, event.target.value)
                  }
                  onBlur={() => onInputBlur(`beveragesSales`)}
                />
              </FormControl>
            </div>
            <div className="input-field-margin-bottom">
              <div className="form-field-name">Covers</div>
              <FormControl>
                <Input
                  variant="filled"
                  placeholder="Enter Number Of Guests"
                  className={`input-field ${
                    sale?.errors?.covers ? 'input-with-error' : ''
                  }`}
                  name="covers"
                  id="covers"
                  value={sale?.covers}
                  type="text"
                  helperText={sale?.errors?.covers}
                  onKeyUp={(event) =>
                    enterKeyHelper(event, () => onClickAddSale(action))
                  }
                  onChange={(event) =>
                    onChangeDetail('covers', event.target.value)
                  }
                  onBlur={() => onInputBlur('covers')}
                />
              </FormControl>
            </div>
            <div className="form-button-row">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.SALE)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                loader={
                  getCategoriesInAddSaleLoaderAction ||
                  updateSaleDetailsLoaderAction
                }
                onClick={() =>
                  onClickAddSale(action === 'add' ? 'add' : 'edit')
                }
              >
                {action === 'add' ? 'Add Sale' : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      )}
      {isSubmittedSuccessfully && (
        <CustomModal
          header="Sale Added Confirmation"
          buttons={okButton}
          className="confirmation-modal"
          headerClassName="confirmation-modal-header"
          bodyClassName="confirmation-modal-body"
          footerClassName="confirmation-modal-footer"
          hideModal={() => {
            setIsSubmittedSuccessfully(false);
            history.push(ROUTE_CONSTANTS_VARIABLE.SALE);
          }}
          successModal
        >
          <>
            <img src={rightIcon} />
            <span>Sale added successfully</span>
          </>
        </CustomModal>
      )}
    </div>
  );
};

export default AddSale;
