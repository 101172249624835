import { displayErrors } from '../../helpers/errorNotifyHelper';
import { saveFCMTokenToLocalStorage } from '../../helpers/localstoragehelper';
import notificationApiServices from '../../services/apiService/firebaseApiServices/notificationApiServices';
import { NOTIFICATION_REDUX_CONSTANTS } from '../reduxConstants/notificationReduxConstants';

export const saveFCMToken = (firebaseToken: string, cb: () => void) => {
  return async () => {
    try {
      const response = await notificationApiServices.connectFirebase({
        firebaseToken,
      });
      if (response.status === 200) {
        saveFCMTokenToLocalStorage(firebaseToken);
        cb();
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getAllNotificationAction = () => {
  return async (dispatch: any) => {
    try {
      const response = await notificationApiServices.getAllNotification();
      if (response.status === 200) {
        dispatch({
          type: NOTIFICATION_REDUX_CONSTANTS.GET_ALL_NOTIFICATION_LIST,
          data: response.data.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const clearNotificationAction = (notificationId?: string) => {
  return async () => {
    try {
      const response = await notificationApiServices.clearNotification(
        notificationId
      );
      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const disconnectFirebase = (firebaseToken: string) => {
  return async () => {
    try {
      const response = await notificationApiServices.disconnectFirebase({
        firebaseToken,
      });
    } catch (e) {
      displayErrors(e);
    }
  };
};
