import _ from 'lodash';

export const replaceHiddenCharacters = (string: string) => {
  return string?.toString()?.replace(/[\u200c\u200b]/g, '');
};

export const checkForEmail = (emailAddress: string) => {
  return /.+@.+\.[A-Za-z]+$/.test(emailAddress);
};

export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

/* START -------------------------------------------------------------- isEmpty Field */
export const isEmpty = (val: any): boolean => {
  let isValEmpty = true;
  if (!_.isNil(val) && _.trim(String(val)).length > 0) {
    isValEmpty = false;
  }
  return isValEmpty;
};

/* END -------------------------------------------------------------- isEmpty Field */

const isValidateEmail = (email: any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isEmail = (fieldName: string, val: string) => {
  if (isEmpty(val)) {
    return {
      status: false,
      message: `Please enter ${fieldName} address`,
    };
  } else if (!isValidateEmail(val)) {
    return { status: false, message: `Invalid ${fieldName} address.` };
  }
  return { status: true, message: '' };
};

/* START -------------------------------------------------------------- Required Input */
export const requireValidate = (fieldName: string, value: any) => {
  if (
    !value?.toString()?.trim()?.length ||
    value === undefined ||
    value === null
  ) {
    return {
      status: false,
      message: `Please enter ${fieldName}`,
    };
  }
  return { status: true, message: '' };
};
/* END -------------------------------------------------------------- Required Input */

/* START -------------------------------------------------------------- Password Validate */
export const passwordValidate = (
  fieldName: string,
  password: string = '',
  isShowValidation: boolean = false,
  fieldName2?: string
) => {
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/;
  if (password === '' || password === undefined || password === null) {
    return {
      status: false,
      message: `Please enter ${fieldName}`,
    };
  }
  if (!passwordRegex.test(password) && isShowValidation) {
    return {
      status: false,
      message: `The ${fieldName} should contain at least 8 letters, one uppercase and one special character.`,
    };
  }
  if (fieldName2 !== undefined && password !== fieldName2) {
    return {
      status: false,
      message: `Password and Confirm Password does not match.`,
    };
  }

  return { status: true, message: '' };
};

/* END -------------------------------------------------------------- Password Validate */
