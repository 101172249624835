export const color_palette = {
  primary: '#F21D86',
  lightPrimary: '#FFBBDD',
  white: '#fff',
  placeholder: '#6d6b6b',
  greyColor: '#787676',
  drakGrey: '#666',
};

export const supplierSpendingChartColors = [
  color_palette.primary,
  '#96397C',
  '#64AEFF',
  '#B78876',
  color_palette.greyColor,
  '#FF725E',
  '#FFBE9D',
  color_palette.lightPrimary,
  '#0b4c9f',
  '#4CD964',
];
